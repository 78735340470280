import { settingsApi } from '../../utils/api';

export const getConfigs = (data) => ({
	type: 'GET_CONFIGS',
	payload: data,
	loading: false,
});
export const loadingConfigs = () => ({
	type: 'LOADING_CONFIGS',
	loading: true,
});

export const fetchConfigs = () => (dispatch) => {
	dispatch(loadingConfigs());
	settingsApi.getConfigs().then(({ data }) => dispatch(getConfigs(data)));
};
export const fetchZoomLink = () => async (dispatch) => {
	dispatch({ type: "FETCH_ZOOM_LINK_REQUEST" });
	try {
	  const response = await settingsApi.get_add({ type: "zoom_link" });
	  dispatch({ type: "FETCH_ZOOM_LINK_SUCCESS", payload: response.data });
	} catch (error) {
	  dispatch({ type: "FETCH_ZOOM_LINK_FAILURE", error });
	}
  };