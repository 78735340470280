import dayjs from "dayjs";
import { appointmentsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

const initialState = {
  appointments: [],
  substitute: [],
  loading: false,
  history: [],
  redoStack: [],
  error: "",
};


const substitutionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TEACHERS_PLAN":
      console.log("Payload in GET_TEACHERS_PLAN:", action.payload); // Проверьте, есть ли `subject_substitute`
      return {
        ...state,
        substitute: action.payload.substitute,
        appointments: action.payload.appointments, // Убедитесь, что здесь есть `subject_substitute`
        config: action.payload.config,
        loading: action.loading,
      };
    case "LOADING_APPOINTMENTS":
      return {
        ...state,
        loading: action.loading,
      };
    case "ADD_APPOINTMENTS":
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
        loading: action.loading,
        error: action.error,
        history: [...state.history, { type: "add", lesson: action.payload }],
        redoStack: [...state.redoStack],
      };
    case "CANCEL_APPOINTMENTS":
      return {
        ...state,
        loading: action.loading,
      };
    case "UPDATE_APPOINTMENTS": {
      console.log("Payload in UPDATE_APPOINTMENTS:", action.payload); // Проверьте, есть ли `subject_substitute`
      return {
        ...state,
        appointments: state.appointments.map((item) =>
          item.id === action.payload.id
            ? { ...item, ...action.payload } // Обновляем все поля, включая `subject_substitute`
            : item
        ),
        loading: action.loading,
      };
    }
    case "ADD_SUBSTITUTION":
      return {
        ...state,
        substitute: [...state.substitute, action.payload],
        loading: action.loading,
      };
    case "UPDATE_SUBSTITUTION":
      return {
        ...state,
        substitute: state.substitute.map((item) =>
          item.id === action.payload.id
            ? { ...item, ...action.payload } // Убедись, что `subject_substitute` обновляется
            : item
        ),
        loading: action.loading,
      };
    case "REMOVE_APPOINTMENTS": {
      const removedLesson = state.appointments.find(
        (item) => item.id === action.payload.appointment
      );

      return {
        ...state,
        appointments: state.appointments.filter(
          (item) => item.id !== action.payload.appointment
        ),
        loading: action.loading,
        history: [...state.history, { type: "remove", lesson: removedLesson }],
        redoStack: [...state.redoStack],
      };
    }
    case "REMOVE_SUBSTITUTION":
      return {
        ...state,
        appointments: state.appointments.map((item) => {
          if (item.id === action.payload.appointment) {
            return {
              ...item,
              is_substitute: "",       // Убираем флаг замены
              subject_substitute: "",  // Очищаем предмет замены (аналогично)
            };
          }
          return item;
        }),
        loading: action.loading,
      };
      

    // ** Undo Redo Functionality
    case "UNDO_ADD": {
      const { lessonId, updatedHistory } = action.payload;
      return {
        ...state,
        appointments: state.appointments.filter((lesson) => lesson.id !== lessonId),
        history: updatedHistory,
        redoStack: [...state.redoStack, { type: "add", lesson: state.appointments.find((lesson) => lesson.id === lessonId) }],
      };
    }

    case "UNDO_EDIT": {
      const { updatedLesson, updatedHistory } = action.payload;
      const currentLesson = state.appointments.find(
        (lesson) => lesson.id === updatedLesson.id
      );
      return {
        ...state,
        appointments: state.appointments.map((lesson) =>
          lesson.id === updatedLesson.id ? updatedLesson : lesson
        ),
        history: updatedHistory,
        redoStack: [
          ...state.redoStack,
          { type: "edit", originalLesson: updatedLesson, updatedLesson: currentLesson },
        ],
      };
    }

    case "UNDO_REMOVE": {
      const { serverLesson, updatedHistory } = action.payload;
      return {
        ...state,
        appointments: [...state.appointments, serverLesson],
        history: updatedHistory,
        redoStack: [...state.redoStack, { type: "remove", lesson: serverLesson }],
      };
    }

    case "REDO_ADD": {
      const { serverLesson, updatedRedoStack } = action.payload;
      return {
        ...state,
        appointments: [...state.appointments, serverLesson],
        history: [...state.history, { type: "add", lesson: serverLesson }],
        redoStack: updatedRedoStack,
      };
    }

    case "REDO_EDIT": {
      const { updatedLesson, updatedRedoStack } = action.payload;
      const redoAction = state.redoStack[state.redoStack.length - 1];
      const original = redoAction.originalLesson;
      return {
        ...state,
        appointments: state.appointments.map((lesson) =>
          lesson.id === updatedLesson.id ? updatedLesson : lesson
        ),
        history: [
          ...state.history,
          { type: "edit", originalLesson: original, updatedLesson },
        ],
        redoStack: updatedRedoStack,
      };
    }

    case "REDO_REMOVE": {
      const { lessonId, updatedRedoStack } = action.payload;
      return {
        ...state,
        appointments: state.appointments.filter((lesson) => lesson.id !== lessonId),
        history: [...state.history, { type: "remove", lesson: state.appointments.find((lesson) => lesson.id === lessonId) }],
        redoStack: updatedRedoStack,
      };
    }

    default:
      return state;
  }

};

export default substitutionsReducer;
