/* eslint-disable react/no-array-index-key */

import React from "react";
import "./Schedule.scss";
import { Button, Chip, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";

import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useDispatch, useSelector } from "react-redux";
import EditLessonDialog from "./EditLessonDialog";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import colorsList from "./colorsList";
import { sortGroupsByClassAndSad } from "../../utils/helpers/sorting";
import { log } from "../../utils/helpers/tracker";
import { appointmentsApi } from "../../utils/api";
import { getPermissions } from "../../utils/helpers/getDefaultUser";

import {
  fetchTeachersPlanHidden,
  redoAction,
  undoAction,
} from "../../redux/actions/appointmentsActions";
import store from "../../redux/store";
// dayjs.locale("ru");

const week = [
  { day: "понедельник" },
  {
    day: "вторник",
  },
  {
    day: "среда",
  },
  { day: "четверг" },
  {
    day: "пятница",
  },
  { day: "суббота" },
];

const daysOfWeekMap = {
  понедельник: 1,
  вторник: 2,
  среда: 3,
  четверг: 4,
  пятница: 5,
  суббота: 6,
  воскресенье: 7,
};

const getDateForDayOfWeek = (relativeWeek, dayOfWeek) => {
  const today = dayjs();
  const currentDay = today.day(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

  // Calculate the difference in days to the target day of the week
  let dayOffset = daysOfWeekMap[dayOfWeek] - currentDay;
  if (dayOffset <= 0) {
    dayOffset += 7; // Ensure positive offset for future dates
  }

  const dayNumber = daysOfWeekMap[dayOfWeek];

  const now = dayjs();
  const currentDayNumber = now.day() === 0 ? 7 : now.day();
  const currentWeekStart = now.subtract(currentDayNumber - 1, "day");
  // Determine the start of the selected week
  let startOfWeek;
  if (relativeWeek === "last") {
    startOfWeek = currentWeekStart.subtract(1, "week");
  } else if (relativeWeek === "next") {
    startOfWeek = currentWeekStart.add(1, "week");
  } else {
    startOfWeek = currentWeekStart;
  }
  // Calculate the new date based on the selected week and day
  const newDate = startOfWeek.add(dayNumber - 1, "day");

  return newDate.format("YYYY-MM-DD");
};

const ScrollArea = ({ direction }) => (
  <div
    className={`scroll-area scroll-area-${direction}`}
    style={{
      position: "sticky",
      [direction]: "-10px",
      left: 0,
      right: 0,
      height: "50px",
      backgroundColor: "rgba(128, 128, 128, 0.5)",
      zIndex: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "16px",
      color: "#000",
      border: "1px dashed  black",
    }}
  >
    {direction === "top"
      ? "переместите для прокручивания вверх "
      : "переместите для прокручивания вниз"}
  </div>
);

const ClassScheduleView = ({
  appointments,
  entitiesTypes,
  addAppointment,
  updAppointment,
  config,
  locations,
  selectedLocation,
  ...props
}) => {

  const permissions = getPermissions();
  const dispatch = useDispatch();

  const [lessons, setLessons] = React.useState([]);
  const [schedule, setSchedule] = React.useState([]);
  const [timeSlots, setTimeSlots] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [teachersColorMap, setTeachersColorMap] = React.useState([]);
  const [blinkingCells, setBlinkingCells] = React.useState([]);
  const tableRef = React.useRef(null);
  const [weeklyLessons, setWeeklyLessons] = React.useState({
    lastWeek: [],
    currentWeek: [],
    nextWeek: [],
  });
  // set table transporting
  const [mode, setMode] = React.useState("row");
  const [groups, setGroups] = React.useState(entitiesTypes.group);
  const [selected, setSelected] = React.useState("current");
  const [selectedWeek, setSelectedWeek] = React.useState([]);
  const [isTableLocked, setIsTableLocked] = React.useState(false);
  const { history, redoStack } = useSelector(
    (state) => state.appointmentsAndSubstitute
  );
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);


  const handleLockTable = () => {
    setIsTableLocked(!isTableLocked);
  };

  const handleRotateTable = () => {
    setMode(mode === "row" ? "column" : "row");
  };



  const checkCopyIsAvailable = (location) => {
    if (permissions.includes("appointments.copy")) {
      return true;
    }
    return false;
  };

  const handleCopyTable = () => {
    const isConfirmed = window.confirm("Вы уверены, что хотите скопировать таблицу?");
    if (!isConfirmed) return;
    if (!selectedLocation) {
      return;
    }
    setLoading(true);
    appointmentsApi.copy(selectedLocation).then((response) => {
      if (response.status === 200) {
        // Логирование успешного копирования
      log(
        "Copy Table Success",
        {
          location: selectedLocation,
        },
        { type: "Table Interaction" }
      );
        dispatch(fetchTeachersPlanHidden(selectedLocation)).then(() => {
          setLoading(false);
        });
      }
    });
  };

  // Edit lesson dialog actions
  const [isEditLessonDialogOpen, setIsEditLessonDialogOpen] =
    React.useState(false);
  const [selectedlesson, setSelectedLesson] = React.useState(null);
  const handleEditLessonDialogClose = () => {
    setIsEditLessonDialogOpen(false);
  };
  const handleEditLessonDialogOpen = (les) => {
    setSelectedLesson(les);
    setIsEditLessonDialogOpen(true);
  };

  // Make the cell blink when a lesson is moved or added/edited
  const makeBlink = (groupVal, day, cellIndex) => {
    const cellKey = `${groupVal.value}-${day}-${cellIndex}`;
    setBlinkingCells((prev) => [...prev, cellKey]);
    setTimeout(() => {
      setBlinkingCells((prev) => prev.filter((cell) => cell !== cellKey));
    }, 3000);
  };


  const handleSwitchWeek = (switchName) => {
    if (selected === switchName) {
      setSelected(null);
    } else {
      setSelected(switchName);
    }
  };
  const [selectedSize, setSelectedSize] = React.useState("100%");
  const [createdLessonId, setCreatedLessonId] = React.useState(null);

  const handleSizeSelect = (e, size) => {
    setSelectedSize(size);
  };

  // Add/edit lesson actions
  const handleCellChange = (lesson, row, day, place, time, lessonInCell, e) => {
     console.log("day", day);
     console.log("place", place);
     console.log("lessonInCell", lessonInCell);
    if (lessonInCell.length === 1 && lesson) {
       console.log("EDIT_LESSON");
      handleEditLessonDialogOpen({
        type: "edit",
        lesson,
      });
    } else if (lessonInCell.length > 1 && lesson) {
       console.log("EDIT_LESSON_FROM_FULL_CELL");
      handleEditLessonDialogOpen({
        type: "edit",
        lesson,
      });
    } else if (lessonInCell.length === 0 && !lesson) {
       console.log("ADD_LESSON");
       // Логирование добавления урока
    log(
      "Appointment Added",
      {
        dayOfWeek: day,
        time,
        place,
        group: row,
        week: selected,
      },
      { type: "Appointment Interaction" }
    );
      handleEditLessonDialogOpen({
        type: "add",
        lesson: {
          dayOfWeek: day,
          time,
          place,
          group: row,
          week: selected,
        },
      });
    }
  };

  const handleUndo = () => {
    if (history.length === 0 || isButtonDisabled) return; // Если история пуста или кнопка заблокирована, выходим
  
    // Получаем последнее действие из истории
    const lastAction = history[history.length - 1];
  
    // Логирование отмены действия
    log(
      "Undo Action",
      {
        action: lastAction, // Данные о действии, которое отменяется
      },
      { type: "History Interaction" }
    );
  
    setIsButtonDisabled(true); // Блокируем кнопки
    dispatch(undoAction()); // Выполняем действие
  
    // Разблокируем кнопки через 1 секунду
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };
  
  const handleRedo = () => {
    if (redoStack.length === 0 || isButtonDisabled) return; // Если стек redo пуст или кнопка заблокирована, выходим
  
    // Получаем последнее действие из стека redo
    const lastRedoAction = redoStack[redoStack.length - 1];
  
    // Логирование повторного выполнения действия
    log(
      "Redo Action",
      {
        action: lastRedoAction, // Данные о действии, которое повторяется
      },
      { type: "History Interaction" }
    );
  
    setIsButtonDisabled(true); // Блокируем кнопки
    dispatch(redoAction()); // Выполняем действие
  
    // Разблокируем кнопки через 1 секунду
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };

  const handleSaveChanges = (newData, id) => {
     // Логирование редактирования урока
  log(
    "Appointment Edited",
    {
      id,
      ...newData, // Все данные об уроке
    },
    { type: "Appointment Interaction" }
  );
    setCreatedLessonId(id);
    // find place by newLesson create date, we need to blink this cell
    const lessonTime = dayjs(newData.date).format("HH:mm:ss");
    const nearestSmallerTimeSlot = timeSlots.reduce(
      (prev, curr) => (curr <= lessonTime ? curr : prev),
      timeSlots[1]
    );
    const place = (timeSlots.indexOf(nearestSmallerTimeSlot) + 1).toString();
    const dateString = newData.date;
    const date = dayjs(dateString);
    const dayOfWeekNumber = date.day();

    const dayOfWeek = Object.keys(daysOfWeekMap).find(
      (key) => daysOfWeekMap[key] === dayOfWeekNumber
    );
    makeBlink({ value: newData.group_id }, dayOfWeek, place);
  };

  // Drag and drop actions
  const handleDragStart = (e, lesson) => {
    if (!lesson) {
      return;
    }
    e.dataTransfer.setData("lesson", JSON.stringify(lesson));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, day, group, cellIndex, cellTime) => {
    if (!e.dataTransfer.getData("lesson")) {
      return;
    }
    if (e.target.classList.contains("lesson-cell")) {
      e.preventDefault();
    }
    const lesson = JSON.parse(e.dataTransfer.getData("lesson"));
    // Логирование перетаскивания урока
  log(
    "Appointment Moved",
    {
      id: lesson.id,
      from: {
        day: lesson.day,
        time: lesson.time,
        group: lesson.group_id,
      },
      to: {
        day,
        time: cellTime,
        group: group.value,
      },
    },
    { type: "Appointment Interaction" }
  );
    makeBlink(group, day, cellIndex);
    const dayNumber = daysOfWeekMap[day];

    const now = dayjs();
    const currentDayNumber = now.day() === 0 ? 7 : now.day();
    const currentWeekStart = now.subtract(currentDayNumber - 1, "day");
    // Determine the start of the selected week
    let startOfWeek;
    if (selected === "last") {
      startOfWeek = currentWeekStart.subtract(1, "week");
    } else if (selected === "next") {
      startOfWeek = currentWeekStart.add(1, "week");
    } else {
      startOfWeek = currentWeekStart;
    }
    // Calculate the new date based on the selected week and day
    const newDate = startOfWeek
      .add(dayNumber - 1, "day")
      .set("hour", cellTime.split(":")[0])
      .set("minute", cellTime.split(":")[1])
      .set("second", 0)
      .format("YYYY-MM-DD HH:mm:ss");
    const updatedLesson = {
      ...lesson,
      day,
      group_id: group.value,
      place: cellIndex.toString(),
      date: newDate,
    };
    setLoading(true);
    updAppointment({ appointment: updatedLesson }).then(() =>
      setLoading(false)
    );
    // ** Tracking the event start
    log(
      "Appointment Edited",
      { appointmentDetails: { ...updatedLesson } },
      { type: "Appointment Interaction" }
    );
    // ** Tracking the event end
  };

  // get config and time slots from props
  React.useEffect(() => {
    setSchedule(config?.schedule);
    const firstShiftLessons = Object.keys(config?.schedule[0])
      .filter((key) => key.startsWith("1_"))
      .map((key) => config?.schedule[0][key][0]);

    const secondShiftLessons = Object.keys(config?.schedule[0])
      .filter((key) => key.startsWith("2_"))
      .map((key) => config?.schedule[0][key][0]);

    const uniqueLessonTimes = Array.from(
      new Set(["0:00", ...firstShiftLessons, ...secondShiftLessons])
    ).map((time) => dayjs(time, "HH:mm").format("HH:mm"));
    setTimeSlots(uniqueLessonTimes);
  }, []);

  React.useEffect(() => {
    const updatedAppointments = appointments.map((item, index) => {
      if (!item.date) {
        console.log("itemNoDate", item);
      }
      const lessonTime = dayjs(item.date).format("HH:mm:ss");
      const nearestSmallerTimeSlot = timeSlots.reduce(
        (prev, curr) => (curr <= lessonTime ? curr : prev),
        timeSlots[1]
      );
      if (lessonTime < timeSlots[1]) {
        return {
          ...item,
          day: dayjs(item.date).format("dddd"),
          time: "00:00",
          place: "1",
          teacher: findLabelByID(item.user_id, entitiesTypes.user),
        };
      }
      const place = (timeSlots.indexOf(nearestSmallerTimeSlot) + 1).toString();
      return {
        ...item,
        day: dayjs(item.date).format("dddd"),
        time: nearestSmallerTimeSlot,
        place,
        teacher: findLabelByID(item.user_id, entitiesTypes.user),
      };
    });
    setLessons(updatedAppointments);

    const uniqueGroupIds = Array.from(
      new Set(appointments.map((item) => item.group_id))
    );
    const groupsAssignments = groups.filter((group) =>
      uniqueGroupIds.includes(group.value)
    );
    const uniqueTeachers = Array.from(
      new Set([...lessons].map((item) => item.teacher))
    );

    const addColors = (teachers) => {
      const teacherColorMap = {};
      let colorIndex = 0;

      teachers.forEach((teacher) => {
        teacherColorMap[teacher] = colorsList[colorIndex];
        colorIndex++;
        if (colorIndex >= colorsList.length) {
          colorIndex = 0;
        }
      });
      setTeachersColorMap(teacherColorMap);
    };
    addColors(uniqueTeachers);

    setGroups(sortGroupsByClassAndSad(groupsAssignments));
    // console.log("groups", groups);
  }, [appointments, timeSlots]);

  // Filter lessons by week
  React.useEffect(() => {
    // Get the start and end dates for last week, the current week, and the next week
    const currentDate = dayjs();
    // Get the start and end dates for the current, last, next week
    const startOfCurrentWeek = currentDate.startOf("week");
    const endOfCurrentWeek = currentDate.endOf("week");

    const startOfLastWeek = startOfCurrentWeek.subtract(1, "week");
    const endOfLastWeek = startOfCurrentWeek.subtract(1, "second");

    const startOfNextWeek = startOfCurrentWeek.add(1, "week");
    const endOfNextWeek = startOfNextWeek.endOf("week");

    const lastWeekLessons = [...lessons].filter((lesson) =>
      dayjs(lesson.date).isBetween(startOfLastWeek, endOfLastWeek, null, "[]")
    );
    const currentWeekLessons = [...lessons].filter((lesson) =>
      dayjs(lesson.date).isBetween(
        startOfCurrentWeek,
        endOfCurrentWeek,
        null,
        "[]"
      )
    );
    const nextWeekLessons = lessons.filter((lesson) =>
      dayjs(lesson.date).isBetween(startOfNextWeek, endOfNextWeek, null, "[]")
    );
    setWeeklyLessons({
      lastWeek: lastWeekLessons,
      currentWeek: currentWeekLessons,
      nextWeek: nextWeekLessons,
    });
  }, [lessons]);

  React.useEffect(() => {
    if (lessons.length === 0) {
      return;
    }
    if (selected === "last") {
      setSelectedWeek(weeklyLessons.lastWeek);
    }
    if (selected === "current") {
      setSelectedWeek(weeklyLessons.currentWeek);
    }
    if (selected === "next") {
      setSelectedWeek(weeklyLessons.nextWeek);
    }
  }, [selected, weeklyLessons]);

  // ScrollArea and handleScroll. do bigger area for scrolling
  React.useEffect(() => {
    const handleScroll = (e) => {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
        if (e.target.classList.contains("scroll-area-top")) {
          tableRef.current.scrollTop = scrollTop - 15;
        } else if (e.target.classList.contains("scroll-area-bottom")) {
          tableRef.current.scrollTop = scrollTop + 15;
        }
      }
    };
    const scrollAreas = document.querySelectorAll(".scroll-area");
    scrollAreas.forEach((area) =>
      area.addEventListener("dragover", handleScroll)
    );
  }, [tableRef.current]);

  if (appointments.length === 0 || lessons.length === 0) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <div className="class__schedule_actions">
        <div className="class__schedule_week_btns">
          <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
            неделя:
          </span>
          <div>
            {" "}
            <Button
              variant="contained"
              size="small"
              color={selected === "last" ? "primary" : "grey"}
              onClick={() => handleSwitchWeek("last")}
            >
              предыдущая
            </Button>
          </div>
          <div>
            {" "}
            <Button
              variant="contained"
              size="small"
              color={selected === "current" ? "primary" : "grey"}
              onClick={() => handleSwitchWeek("current")}
            >
              текущая
            </Button>
          </div>
          <div>
            {" "}
            <Button
              variant="contained"
              size="small"
              color={selected === "next" ? "primary" : "grey"}
              onClick={() => handleSwitchWeek("next")}
            >
              следующая
            </Button>
          </div>
        </div>
        <div onClick={handleLockTable}>
          {" "}
          {isTableLocked ? (
            <span style={{ cursor: "pointer" }}>
              <Tooltip title="Запретить редактирование">
                <LockOpenIcon />
              </Tooltip>
            </span>
          ) : (
            <span style={{ color: "#f44336", cursor: "pointer" }}>
              <Tooltip title="Разрешить редактирование">
                <LockIcon />
              </Tooltip>
            </span>
          )}
        </div>
        <div className="class__schedule_history_btns">
  <Button
    variant="contained"
    onClick={handleUndo}
    size="small"
    style={{ marginRight: "20px" }}
    disabled={history.length === 0 || isButtonDisabled} // Отключаем кнопку, если история пуста или кнопка заблокирована
  >
    <UndoIcon />
  </Button>
  <Button
    variant="contained"
    onClick={handleRedo}
    size="small"
    disabled={redoStack.length === 0 || isButtonDisabled} // Отключаем кнопку, если стек redo пуст или кнопка заблокирована
  >
    <RedoIcon />
  </Button>
</div>
        <div className="class__schedule_scale">
          <Chip
            label="100%"
            value="100%"
            size="small"
            disabled
            variant="outlined"
            onClick={(e) => {
              handleSizeSelect(e, "100%");
            }}
          />
          <Chip
            label="200%"
            value="200%"
            size="small"
            disabled
            variant="outlined"
            onClick={(e) => {
              handleSizeSelect(e, "200%");
            }}
          />
          <Chip
            label="300%"
            value="300%"
            size="small"
            disabled
            variant="outlined"
            onClick={(e) => {
              handleSizeSelect(e, "300%");
            }}
          />
        </div>
        <div className="class__schedule_helps">
          <div>
            <Button
              onClick={handleCopyTable}
              variant="contained"
              size="small"
              disabled={!checkCopyIsAvailable(selectedLocation)}
            >
              Скопировать
            </Button>
          </div>
          <div>
            <Button
              onClick={handleRotateTable}
              variant="contained"
              size="small"
            >
              Повернуть таблицу
            </Button>
          </div>
        </div>
      </div>

      <ScrollArea direction="top" />

      <div
        className="schedule"
        style={{ opacity: loading ? 0.4 : 1 }}
        ref={tableRef}
      >
        {loading && (
          <div className="schedule_cpoying_text">идет копирование...</div>
        )}

        <table
          className="schedule__table"
          style={{
            width: mode === "column" ? `${groups.length * 200}px` : "100%",
            userSelect: isTableLocked ? "auto" : "none",
            pointerEvents: isTableLocked ? "auto" : "none",
          }}
        >
          <thead>
            <tr>
              <th>Группа</th>
              {mode === "row"
                ? week.map((slot, index) => {
                  const dateDayOfWeek = getDateForDayOfWeek(
                    selected,
                    slot.day
                  );
                  return (
                    <th
                      key={index}
                      style={{ position: "sticky", top: "0", zIndex: "10" }}
                    >
                      {slot.day} <br />
                      {dateDayOfWeek}
                    </th>
                  );
                })
                : groups.map((slot, index) => (
                  <th
                    key={index}
                    style={{ position: "sticky", top: "0", zIndex: "10" }}
                  >
                    {slot.label}{" "}
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {mode === "column"
              ? week?.map((w, indexx) => {
                const dateDayOfWeek = getDateForDayOfWeek(selected, w.day);

                return (
                  <tr key={indexx}>
                    <td>
                      <span className="schedule__group_label">
                        {w.day} {dateDayOfWeek}
                      </span>
                    </td>
                    {groups?.map((group, groupIndex) => (
                      <td key={group.group_id}>
                        {Array.from({ length: timeSlots.length }).map(
                          (_, index) => {
                            const lessonInCell = selectedWeek
                              .filter(
                                (lesson) =>
                                  lesson.group_id === group.value &&
                                  lesson.day === w.day &&
                                  lesson.place === (index + 1).toString()
                              )
                              .filter(Boolean);
                            const lessonTime = timeSlots[index];
                            return (
                              <div
                                key={index}
                                className={`lesson-cell ${blinkingCells.includes(
                                  `${group.value}-${w.day}-${index + 1}`
                                )
                                  ? "blinking"
                                  : ""
                                  }`}
                                onDrop={(e) =>
                                  handleDrop(
                                    e,
                                    w.day,
                                    group,
                                    index + 1,
                                    lessonTime
                                  )
                                }
                                onDragOver={(e) => handleDragOver(e)}
                                onDoubleClick={(e) => {
                                  handleCellChange(
                                    null,
                                    group,
                                    w.day,
                                    index + 1,
                                    lessonTime,
                                    lessonInCell,
                                    e
                                  );
                                }}
                              >
                                <span className="lesson-number">{index}</span>
                                {groupIndex === 0 ? (
                                  <div className="lesson-time">
                                    {lessonTime}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* <span className="cell-date">
                                    {dateDayOfWeek}
                                  </span> */}

                                {lessonInCell.map((lesson) => (
                                  <div
                                    key={uuidv4()}
                                    className="lesson"
                                    style={{
                                      width: `${100 / lessonInCell.length}%`,
                                      backgroundColor: `${teachersColorMap[lesson.teacher]
                                        }`,
                                      color: "#000",
                                    }}
                                    onDragStart={(e) =>
                                      handleDragStart(e, lesson)
                                    }
                                    draggable
                                    onDoubleClick={(e) => {
                                      handleCellChange(
                                        lesson,
                                        group,
                                        w.day,
                                        index + 1,
                                        lessonTime,
                                        lessonInCell,
                                        e
                                      );
                                    }}
                                  >
                                    {findLabelByID(
                                      lesson.subject,
                                      entitiesTypes.subject
                                    )}{" "}
                                    - {lesson.teacher}
                                    <div>
                                      {dayjs(lesson.date).format("HH:mm")}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            );
                          }
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })
              : groups?.map((group, groupIndex) => (
                <tr key={group.value}>
                  <td>
                    <span className="schedule__group_label">
                      {group.label}
                    </span>{" "}
                  </td>
                  {week?.map((w) => (
                    <td key={w.day}>
                      {Array.from({ length: timeSlots.length }).map(
                        (_, index) => {
                          const lessonInCell = selectedWeek
                            .filter(
                              (lesson) =>
                                lesson.group_id === group.value &&
                                lesson.day === w.day &&
                                lesson.place === (index + 1).toString()
                            )
                            .filter(Boolean);
                          const lessonTime = timeSlots[index];
                          // const dateDayOfWeek = getDateForDayOfWeek(
                          //   selected,
                          //   w.day
                          // );
                          return (
                            <div
                              key={index}
                              className={`lesson-cell ${blinkingCells.includes(
                                `${group.value}-${w.day}-${index + 1}`
                              )
                                ? "blinking"
                                : ""
                                }`}
                              onDrop={(e) =>
                                handleDrop(
                                  e,
                                  w.day,
                                  group,
                                  index + 1,
                                  lessonTime
                                )
                              }
                              onDragOver={(e) => handleDragOver(e)}
                              onDoubleClick={(e) => {
                                handleCellChange(
                                  null,
                                  group,
                                  w.day,
                                  index + 1,
                                  lessonTime,
                                  lessonInCell,
                                  e
                                );
                              }}
                            >
                              <span className="lesson-number">{index}</span>
                              {w.day === "понедельник" ? (
                                <div className="lesson-time">
                                  {lessonTime}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* <span className="cell-date">
                                  {dateDayOfWeek}
                                </span> */}
                              {lessonInCell.map((lesson) => (
                                <div
                                  key={uuidv4()}
                                  className="lesson"
                                  style={{
                                    width: `${100 / lessonInCell.length}%`,
                                    backgroundColor: `${teachersColorMap[lesson.teacher]
                                      }`,
                                    color: "#000",
                                  }}
                                  onDragStart={(e) =>
                                    handleDragStart(e, lesson)
                                  }
                                  draggable
                                  onDoubleClick={(e) => {
                                    handleCellChange(
                                      lesson,
                                      group,
                                      w.day,
                                      index + 1,
                                      lessonTime,
                                      lessonInCell,
                                      e
                                    );
                                  }}
                                >
                                  {findLabelByID(
                                    lesson.subject,
                                    entitiesTypes.subject
                                  )}{" "}
                                  - {lesson.teacher}
                                  <div>
                                    {dayjs(lesson.date).format("HH:mm")}
                                  </div>
                                </div>
                              ))}
                            </div>
                          );
                        }
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>

        <ScrollArea direction="bottom" />
      </div>
      <EditLessonDialog
        open={isEditLessonDialogOpen}
        handleClose={handleEditLessonDialogClose}
        lesson={selectedlesson}
        handleSaveChanges={handleSaveChanges}
        entities={entitiesTypes}
        addAppointment={addAppointment}
        updAppointment={updAppointment}
        lessons={lessons}
        setLessons={setLessons}
      />
    </>
  );
};

export default ClassScheduleView;
