import axios from "../../config/axiosConfig";

export default {
  getConfigs: () => axios.get(`/configs/get`),
  updConfig: (data) => axios.post(`/config/update`, data),
  get_add: (data) => axios.post("/config/add", data),
  get_del: (data) => {
    // Формируем квери строку для передачи параметра id
    const deleteUrl = `/config/remove?id=${data.id}`;
    
    // Логируем сформированный URL
    console.log("URL для удаления:", deleteUrl);

    // Отправляем GET-запрос с параметром в URL
    return axios.get(deleteUrl)
      .then((response) => {
        console.log("Ответ от сервера:", response); // Логируем ответ
        return response;
      })
      .catch((error) => {
        console.error("Ошибка при удалении:", error); // Логируем ошибку
        throw error;
      });
  },
};
