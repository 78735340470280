import {
  Autocomplete,
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ReactInputMask from "react-input-mask";
import React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { min } from "date-fns";

export const ReusableTextField = ({
  label,
  value,
  onChange,
  name,
  type,
  multiline,
  options,
  select,
  disabled,
  error,
  inputProps,
  onKeyDown,
  placeholder,
  style,
  required,
  rows,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "5px",
    }}
  >
    <div style={{ marginRight: "5px", minWidth: "120px", fontSize: "14px" }}>
      {label}
    </div>
    <TextField
      // variant="standard"
      // variant="filled"
      value={value}
      onChange={onChange}
      name={name}
      disabled={disabled}
      fullWidth
      size="small"
      type={type}
      multiline={multiline}
      rows={rows}
      select={select}
      error={error}
      inputProps={inputProps}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      style={style}
      required={required}
      sx={{
        backgroundColor: "#fff",
        borderRadius: "4px",
      }}
      min={min}
    >
      {options &&
        options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  </div>
);

export const ReusableAutocomplete = ({
  label,
  ref,
  value,
  onChange,
  inputValue,
  onInputChange,
  options,
  getOptionLabel,
  name,
  id,
  freeSolo,
  defaultValue,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "5px",
    }}
  >
    <div style={{ marginRight: "5px", minWidth: "120px", fontSize: "14px" }}>
      {label}
    </div>
    <Autocomplete
      id={id}
      ref={ref}
      defaultValue={defaultValue}
      freeSolo={freeSolo}
      size="small"
      fullWidth
      name={name}
      value={value}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      sx={{
        backgroundColor: "#fff",
      }}
      renderInput={(params) => <TextField {...params} key={params.value} />}
    />
  </div>
);

export const ReusableSelect = ({
  label,
  value,
  onChange,
  name,
  type,
  options,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "5px",
    }}
  >
    <div style={{ marginRight: "5px", minWidth: "120px", fontSize: "14px" }}>
      {label}
    </div>
    <Select
      value={value}
      onChange={onChange}
      name={name}
      fullWidth
      size="small"
      type={type}
      sx={{
        backgroundColor: "#fff",
        borderRadius: "4px",
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value}>{option.label}</MenuItem>
      ))}
    </Select>
  </div>
);

export const MaskedInput = ({ value, onChange, ...props }) => {
  const determineMask = (val) => {
    if (!val) return "9999";
    if (val.length < 4) return "y999";
    if (val.length === 4) return "y999-nm-z9";
    if (val.charAt(4) === "-") return "y999-nm-z9";
    return "9999";
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: "5px",
      }}
    >
      <div style={{ marginRight: "5px", minWidth: "120px", fontSize: "14px" }}>
        {props.label}
      </div>
      <ReactInputMask
        mask={determineMask(value)}
        formatChars={props.formatChars}
        maskChar={null}
        value={value}
        onChange={onChange}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "4px",
        }}
        size="small"
        fullWidth
      >
        {(inputProps) => (
          <TextField
            {...inputProps}
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
          />
        )}
      </ReactInputMask>
    </div>
  );
};

export const RussianDatePicker = ({ value, onChange, label, inputFormat }) => {
  dayjs.locale("ru");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: "5px",
        border: "none",
      }}
    >
      <div style={{ marginRight: "5px", minWidth: "120px", fontSize: "14px" }}>
        {label}
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="ru">
        <DatePicker
          size="small"
          mask="____-__-__"
          inputFormat={inputFormat}
          value={value}
          onChange={onChange}
          slotProps={{ textField: { size: "small" } }}
          renderInput={(params) => (
            <TextField {...params} style={{ border: "none" }} size="small" />
          )}
          disablePast
          inputProps={{
            style: {
              padding: "5px 8px",
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export const Calendar = ({
  dateRange,
  handleDateChange,
  disabled,
  minmax,
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs} locale="ru">
    <div style={{ display: "flex", gap: "10px" }}>
      {/* DatePicker for start date */}
      <DatePicker
        inputFormat="DD/MM/YYYY"
        label="Период от"
        maxDate={dayjs(minmax.max)}
        minDate={dayjs(minmax.min)}
        value={dateRange[0]}
        onChange={(newValue) => handleDateChange([newValue, dateRange[1]])}
        disabled={disabled}
        renderInput={(params) => <TextField {...params} size="small" />}
      />
      {/* DatePicker for end date */}
      <DatePicker
        inputFormat="DD/MM/YYYY"
        label="Период до"
        maxDate={dayjs(minmax.max)}
        minDate={dayjs(minmax.min)}
        value={dateRange[1]}
        onChange={(newValue) => handleDateChange([dateRange[0], newValue])}
        disabled={disabled}
        renderInput={(params) => <TextField {...params} size="small" />}
      />
    </div>
  </LocalizationProvider>
);





export const DateRangePickerCustom = ({ handleDateRangeChange }) => {
  const [selectedDates, setSelectedDates] = React.useState({
    from: dayjs(new Date()),
    to: dayjs(new Date()),
  });

  const handleFromDateChange = (date) => {
    setSelectedDates((prevState) => {
      const updatedDates = { ...prevState, from: date };
      handleDateRangeChange(updatedDates);
      return updatedDates;
    });
  };

  const handleToDateChange = (date) => {
    setSelectedDates((prevState) => {
      const updatedDates = { ...prevState, to: date };
      handleDateRangeChange(updatedDates);
      return updatedDates;
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="ru">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div style={{ marginRight: "10px" }}>
          <DatePicker
            label="от"
            value={selectedDates.from}
            onChange={handleFromDateChange}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                style={{ width: "10%!important" }}
              />
            )}
          />
        </div>
        <div style={{ marginRight: "10px" }}>
          <DatePicker
            label="до"
            value={selectedDates.to}
            onChange={handleToDateChange}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </div>
        {/* <Box mt={2}>
          <Typography variant="body1">
            Дата:{" "}
            {JSON.stringify({
              from: selectedDates.from.format("YYYY-MM-DD"),
              to: selectedDates.to.format("YYYY-MM-DD"),
            })}
          </Typography>
        </Box> */}
      </div>
    </LocalizationProvider>
  );
};
