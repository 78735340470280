import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import "./Lessons.css";
import LoadingButton from "@mui/lab/LoadingButton";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { v4 as uuidv4 } from "uuid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContentText,
  Typography,
  Button,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import PupilsTable from "./PupilsTable";

function Lessons({ ...props }) {
  const { days, postLessons, updateLessons, getLessons, loading, lessons } =
    props;

  /* Accordion expended off/on */
  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  /* Dialog action & opened Group data */
  const [selectedGroup, selectGroup] = React.useState({
    isOpened: false,
    data: {},
    date: "",
    id: null,
    topic: "",
  });

  const width = window.innerWidth;

  const subjects = useSelector((state) => state.entitiesTypes.subject);

  const handleOpen = (group, date, id, topic) => {
    selectGroup({ isOpened: true, data: group, date, id, topic });
  };
  const handleClose = () =>
    selectGroup({ isOpened: false, data: {}, date: "", id: null, topic: "" });

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (selectedGroup.isOpened) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [selectedGroup.isOpened]);

  /* Dialog action */

  const daySwitcher = (day) => {
    switch (day) {
      case 1:
        return "Понедельник";
      case 2:
        return "Вторник";
      case 3:
        return "Среда";
      case 4:
        return "Четверг";
      case 5:
        return "Пятница";
      case 6:
        return "Суббота";
      case 0:
        return "Воскресенье";
      default:
        return "нет такого дня";
    }
  };

  const getDay = (date) => {
    const currentDate = new Date(date);
    return daySwitcher(currentDate.getDay());
  };

  /* sort accordions by date. sort {"date1": {}, "date5": {}..."date3": {} } by date */
  const sortedDays = {};
  Object.keys(days)
    .sort()
    .forEach((key) => {
      sortedDays[key] = days[key];
    });

  return (
    <>
      <div className="table__wrapper">
        {lessons.length ? null : (
          <div className="empty__fields">нет данных для отображения</div>
        )}
        {Object.keys(sortedDays).map((key, index) => {
          const item = sortedDays[key];
          item.sort((a, b) => a.time.localeCompare(b.time));

          return (
            <div className="accordionItem" id={index} key={uuidv4()}>
              <Accordion
                expanded={expanded === index}
                onChange={handleChangeAccordion(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography
                    variant="h5"
                    component="div"
                    style={{ fleGrow: 1 }}
                  >
                    {getDay(key)} <span className="lessonDate">{key}</span>
                  </Typography>
                </AccordionSummary>

                {item.map((group, ind) => (
                  <AccordionDetails
                    id={ind}
                    key={uuidv4()}
                    style={{ transitionDelay: "500ms" }}
                  >
                    <Typography component="span">
                      <div className="group__information">
                        <div className="groupName__icon">
                          {group.completed ? (
                            <TaskAltIcon style={{ color: "green" }} />
                          ) : (
                            <span
                              className="iconSpan"
                              style={{ minWidth: "20px" }}
                            />
                          )}
                          <span className="groupName">{group.name_group}</span>
                          {width < 600 ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span className="groupName">{group.time}</span>
                              <span className="groupName">
                                {subjects
                                  ? subjects.find(
                                      (elem) => elem.value === group.subject
                                    ).label
                                  : null}
                              </span>
                            </div>
                          ) : (
                            <>
                              <span className="groupName">{group.time}</span>
                              <span className="groupName">
                                {subjects
                                  ? subjects.find(
                                      (elem) => elem.value === group.subject
                                    ).label
                                  : null}
                              </span>
                            </>
                          )}

                           
                        </div>

                        <LoadingButton
                          className="tableOpenBtn_lesson"
                          variant="contained"
                          size="small"
                          onClick={() =>
                            handleOpen(group, key, group.id, group.topic)
                          }
                        >
                          {group.id === "None" ? (
                            <span className="tableOpenBtn_lesson">
                              Отметить
                            </span>
                          ) : (
                            <span className="tableOpenBtn_lesson">
                              Изменить
                            </span>
                          )}
                        </LoadingButton>
                      </div>
                    </Typography>
                  </AccordionDetails>
                ))}
              </Accordion>
            </div>
          );
        })}
        <Dialog
          fullScreen
          open={selectedGroup.isOpened}
          onClose={handleClose}
          scroll="paper"
        >
          <DialogContentText
            id="scroll-dialog-description"
            component="div"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {selectedGroup.isOpened ? (
              <PupilsTable
                students={selectedGroup.data.students}
                group={selectedGroup.data}
                date={selectedGroup.date}
                postLessons={postLessons}
                updateLessons={updateLessons}
                handleClose={handleClose}
                loading={loading}
                getLessons={getLessons}
                user_id={lessons[0].user_id}
                days={days}
                subjects={subjects}
              />
            ) : (
              <h3>Загрузка...</h3>
            )}
          </DialogContentText>
        </Dialog>
      </div>
    </>
  );
}

export default Lessons;
