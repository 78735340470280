/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect} from "react";
import "./ConfigsView.scss";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  MenuItem,
  Select,
  Typography,
  Box,
  Divider,
  IconButton,
  TextField,
  FormControl, // Добавлено
  InputLabel,  // Добавлено
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { settingsApi } from "../../utils/api";
import renderConfigDetails from "./ConfigDetails";
import RenderHolidaysConfigs from "./RenderHolidaysConfigs";
import activeUsersSelection from "../../utils/helpers/activeUserSelection";

const configNamesInRussian = {
  administration: "Администраторы",
  responsibility: "Ответственный",
  category: "Категории и операции",
  holidays: "Праздники",
  default_teacher: "Пользователь по умолчанию",
  zoom_params: "Ссылка на Zoom",
};

const ConfigsView = ({ entities, configs,  ...props }) => {
  const {
    user: users,
    location: locations,
    operation: operations,
    category: categories,
    active_users: activeUsers,
    group: groups,
  } = entities;
  console.log("props", props);

  const [configList, setConfigList] = useState(configs);
  const [editMode, setEditMode] = useState(null);
  const [expandedConfig, setExpandedConfig] = useState(null);
  const [editableCategory, setEditableCategory] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(""); 
  const [initialData, setInitialData] = useState(null);
  const [isZoomTabExpanded, setIsZoomTabExpanded] = useState(false);
  const [newZoomConfig, setNewZoomConfig] = useState({
    link: "",
    group_id: null,
  });
  const [isAddingNewConfig, setIsAddingNewConfig] = useState(false); // Флаг для отображения полей
  const responsabilityConfigs = configList.filter(
    (item) => item.name === "responsibility"
  );
  const supportConfigs = configList.filter((item) => item.name === "support");
  const holidaysConfigs = configList.filter((item) => item.name === "holidays");

  
  // console.log("holidaysConfig", holidaysConfigs);


  
  const handleToggleExpand = (configId) => {
    setExpandedConfig((prev) => (prev === configId ? null : configId));
  };

  const [expandResponsabilities, setExpandResponsabilities] =
    React.useState(false);
  const handleToggleExpandResponsabilities = () => {
    setExpandResponsabilities(!expandResponsabilities);
  };
  const [expandSupports, setExpandSupports] = React.useState(false);
  const handleToggleExpandSupports = () => {
    setExpandSupports(!expandSupports);
  };

  const handleEditToggle  = useCallback( (configId) => {
    setEditMode((prev) => (prev === configId ? null : configId));
    // const sendingDrata = configList.find((con) => con.id === configId);
    // settingsApi.updConfig({
    //   config_id: configId,
    //   data: sendingDrata.data,
    //   business: false,
    // });
  }, []);




  const handleConfigChange = useCallback( (configId, key, newValue) => {
    console.log("CHANGE:", configId, key, newValue);
    console.log("newValue", newValue);
    console.log("CONFIGS:", configs);
    if (configId === "simple_config") {
      setConfigList((prevConfigs) =>
        prevConfigs.map((config) =>
          config.name === "default_teacher"
            ? { ...config, data: { ...config.data, user: newValue } }
            : config
        )
      );
      return;
    }
    if (key === "holidays") {
      setConfigList((prevConfigs) =>
        //
        prevConfigs.map((config) =>
          config.id === configId
            ? {
              ...config,
              data: {
                ...config.data,
                [key]: newValue,
              },
            }
            : config
        )
      );
    }

    setConfigList((prevConfigs) =>
      //
      prevConfigs.map((config) =>
        config.id === configId
          ? {
            ...config,
            data: { ...config.data, [key]: newValue },
          }
          : config
      )
    );
  }, []);

  const handleEditCategory = (category) => {
    setEditableCategory(category);
  };

  const handleSaveCategory = (configId) => {
    setEditableCategory(null);
    console.log("Saved Category", editableCategory);
    const sendingDrata = configList.find((con) => con.id === configId);
    settingsApi.updConfig({
      config_id: configId,
      data: sendingDrata.data,
      business: false,
    });
  };

  const handleDeleteCategory = (categoryId) => {
    console.log("Deleting category:", categoryId);
    setConfigList((prevConfigs) =>
      prevConfigs.map((config) =>
        config.name === "category"
          ? {
            ...config,
            data: config.data.filter(
              (category) => category.category !== categoryId
            ),
          }
          : config
      )
    );
  };

  const handleDeleteOperation = (categoryId, operation) => {
    console.log("Deleting operation:", categoryId, operation);
    console.log("configList", configList);
    setConfigList((prevConfigs) =>
      prevConfigs.map((config) =>
        config.name === "category"
          ? {
            ...config,
            data: config.data.map((category) =>
              category.category === categoryId
                ? {
                  ...category,
                  operations: category.operations.filter(
                    (op) => op.toString() !== operation.toString()
                  ),
                }
                : category
            ),
          }
          : config
      )
    );
  };

  const handleOperationChange = (event) => {
    setSelectedOperation(event.target.value);
  };

  const handleAddOperation = (categoryId, config_id) => {
    if (!selectedOperation === "") return;
    const sendingData = {
      config_id,
      data: {
        category: categoryId,
        operations: [
          ...configList
            .find((config) => config.id === config_id)
            .data.find((item) => item.category === categoryId).operations,
          Number(selectedOperation),
        ],
      },
      business: true,
    };
    settingsApi.updConfig(sendingData).then((res) => {
      console.log("res", res);
    });
    console.log("Adding operation:", selectedOperation);
    console.log("categoryID:", categoryId);
    setConfigList((prevConfig) =>
      prevConfig.map((config) => {
        if (config.name === "category") {
          return {
            ...config,
            data: config.data.map((item) =>
              item.category === categoryId
                ? {
                  ...item,
                  operations: [...item.operations, selectedOperation],
                }
                : item
            ),
          };
        }
        return config;
      })
    );
    setSelectedOperation(null);
  };




  const handleDeleteZoomLink = useCallback((configId) => {
    const isConfirmed = window.confirm("Вы уверены, что хотите удалить эту конфигурацию?");
    if (!isConfirmed) return;
  
    // Логируем ID конфигурации
    console.log("Удаление конфигурации с ID:", configId);
  
    // Формируем объект с id, который передается в метод get_del
    const configData = { id: configId };
  
    // Логируем объект данных для удаления
    console.log("Данные для удаления:", configData);
  
    // Вызываем метод для удаления конфигурации через API
    settingsApi.get_del(configData)
      .then((response) => {
        // Логируем полный ответ от сервера
        console.log("Ответ от сервера:", response);
  
        // Если ответ успешен, удаляем конфигурацию из списка
        setConfigList((prevConfigs) => prevConfigs.filter((config) => config.id !== configId));
        
        // Сообщаем пользователю об успешном удалении
        alert("Конфигурация успешно удалена!");
      })
      .catch((error) => {
        // Логируем ошибку и выводим сообщение пользователю
        console.error("Ошибка при удалении конфигурации:", error);
        alert("Не удалось удалить конфигурацию. Попробуйте ещё раз.");
      });
  }, []);

  const handleAddZoomLink = useCallback((link, group_id) => {
    const newConfig = {
      name: "zoom_params",
      data: {
        link,
        group_id,
      },
    };
  
    // Отправляем новую конфигурацию на сервер
    settingsApi.get_add(newConfig)
      .then((response) => {
        console.log("Новая конфигурация создана:", response);
        // Добавляем новую конфигурацию в состояние
        setConfigList((prevConfigs) => [...prevConfigs, newConfig]);
        alert("Новая конфигурация успешно добавлена!")
      })
      
      .catch((error) => {
        console.error("Ошибка при создании конфигурации:", error);
        alert("Не удалось создать новую конфигурацию")
      });
  }, []);

    // Функция для сохранения изменений
    const handleSaveZoomLink = useCallback((configId, link, group_id) => {
      const updatedConfig = {
        config_id: configId,
        business: false, 
        name: "zoom_params",
        data: {
          link,
          group_id,
        },
      };
    
      // Отправляем запрос на обновление
      settingsApi.updConfig(updatedConfig)
        .then((response) => {
          console.log("Конфигурация обновлена:", response);
          // Обновляем состояние
          setConfigList((prevConfigs) =>
            prevConfigs.map((config) =>
              config.id === configId ? response.data : config
            )
          );
          alert("Конфигурация успешно обновлена!");
        })
        .catch((error) => {
          console.error("Ошибка при обновлении конфигурации:", error);
          alert("Не удалось обновить конфигурацию. Попробуйте ещё раз."); 
        });
    }, []);


  // Render styles
  const renderConfigStyles = (config) => ({
    border: "1px solid #ccc",
    padding: "15px",
    marginBottom: "10px",
    backgroundColor: config.id === editMode ? "#f9f9f9" : "#fff",
  });

  const renderConfigsGroupStyles = () => ({
    border: "1px solid #ccc",
    padding: "15px",
    marginBottom: "10px",
    backgroundColor: "#fff",
  });

  const renderResponsabilityConfigs = (source) => (
    <div>
      <div key="1as" style={renderConfigsGroupStyles()}>
        <div className="category-header-expand">
          <h3 style={{ marginBottom: "16px", color: "#37474f" }}>
            Ответственные
          </h3>
          <Button
            onClick={() =>
              handleToggleExpandResponsabilities(!expandResponsabilities)
            }
          >
            {expandResponsabilities ? "Скрыть" : "Развернуть"}
          </Button>
        </div>
        {expandResponsabilities && (
          <div>
            <div>{renderConfigDetails("responsibility")}</div>
            {source.map((config) => (
              <div key={config.id} style={renderConfigStyles(config)}>
                <div className="category-header-expand">
                  <strong>
                    {configNamesInRussian[config.name]}{" "}
                    {findLabelByID(config.data.location, locations)}
                  </strong>
                </div>

                <div>
                  <div>
                    <label>Локация:</label>
                    {editMode === config.id ? (
                      <Select
                        value={config.data.location}
                        onChange={(e) =>
                          handleConfigChange(
                            config.id,
                            "location",
                            e.target.value
                          )
                        }
                        size="small"
                        sx={{
                          minWidth: 80,
                          height: 30,
                          fontSize: 14,
                          padding: "4px 8px",
                        }}
                      >
                        {locations.map((loc) => (
                          <MenuItem key={loc.value} value={loc.value}>
                            {loc.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <span>
                        {findLabelByID(config.data.location, locations)}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Пользователь:</label>
                    {editMode === config.id ? (
                      <Select
                        value={config.data.user}
                        onChange={(e) =>
                          handleConfigChange(config.id, "user", e.target.value)
                        }
                        size="small"
                        sx={{
                          minWidth: 80,
                          height: 30,
                          fontSize: 14,
                          padding: "4px 8px",
                        }}
                      >
                        {activeUsersSelection(users, activeUsers)}
                      </Select>
                    ) : (
                      <span>{findLabelByID(config.data.user, users)}</span>
                    )}
                  </div>
                  <Button onClick={() => handleEditToggle(config.id)}>
                    {editMode === config.id ? (
                      <span
                        onClick={() => {
                          handleSaveCategory(config.id);
                        }}
                      >
                        Сохранить
                      </span>
                    ) : (
                      "Редактировать"
                    )}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const renderSupportConfigs = (supportConfig) => {
    console.log("supportConfig", supportConfig);
    return (
      <div>
        <div key="1as" style={renderConfigsGroupStyles()}>
          <div className="category-header-expand">
            <h3 style={{ marginBottom: "16px", color: "#37474f" }}>
              Ответственные за задачи из техподдержи
            </h3>
            <Button onClick={() => handleToggleExpandSupports(!expandSupports)}>
              {expandSupports ? "Скрыть" : "Развернуть"}
            </Button>
          </div>
          {expandSupports && (
            <div>
              <div>{renderConfigDetails("support")}</div>
              {supportConfig.map((config) => (
                <div key={config.id} style={renderConfigStyles(config)}>
                  <div className="category-header-expand">
                    <strong>
                      {configNamesInRussian[config.name]}{" "}
                      {findLabelByID(config.data.location, locations)}
                    </strong>
                  </div>

                  <div>
                    <div>
                      <label>Локация:</label>
                      {editMode === config.id ? (
                        <Select
                          value={config.data.location}
                          onChange={(e) =>
                            handleConfigChange(
                              config.id,
                              "location",
                              e.target.value
                            )
                          }
                          size="small"
                          sx={{
                            minWidth: 80,
                            height: 30,
                            fontSize: 14,
                            padding: "4px 8px",
                          }}
                        >
                          {locations.map((loc) => (
                            <MenuItem key={loc.value} value={loc.value}>
                              {loc.label}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <span>
                          {findLabelByID(config.data.location, locations)}
                        </span>
                      )}
                    </div>
                    <div>
                      <label>Пользователь:</label>
                      {editMode === config.id ? (
                        <Select
                          value={config.data.user_id}
                          onChange={(e) =>
                            handleConfigChange(
                              config.id,
                              "user_id",
                              e.target.value
                            )
                          }
                          size="small"
                          sx={{
                            minWidth: 80,
                            height: 30,
                            fontSize: 14,
                            padding: "4px 8px",
                          }}
                        >
                          {activeUsersSelection(users, activeUsers)}
                        </Select>
                      ) : (
                        <span>{findLabelByID(config.data.user_id, users)}</span>
                      )}
                    </div>
                    <Button onClick={() => handleEditToggle(config.id)}>
                      {editMode === config.id ? (
                        <span
                          onClick={() => {
                            handleSaveCategory(config.id);
                          }}
                        >
                          Сохранить
                        </span>
                      ) : (
                        "Редактировать"
                      )}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCategoryConfig = (config) => (
    <div key={config.id} style={renderConfigStyles(config)}>
      <div className="category-header-expand">
        <h3>{configNamesInRussian[config.name]}</h3>
        <Button onClick={() => handleToggleExpand(config.id)}>
          {expandedConfig === config.id ? "Скрыть" : "Развернуть"}
        </Button>
      </div>
      {expandedConfig === config.id && (
        <div>
          <div>{renderConfigDetails("category")}</div>
          <div>
            {config.data.map((category) => (
              <div key={category.category}>
                <div className="configs__category_delete">
                  <strong>
                    {findLabelByID(category.category, categories)}
                  </strong>
                  {editableCategory?.category === category?.category && (
                    <span
                      onClick={() => {
                        handleDeleteCategory(category.category);
                      }}
                    >
                      <Delete />
                    </span>
                  )}
                </div>

                <div>
                  Операции:
                  <div style={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                    {category.operations.map((operation, idx) => (
                      <>
                        <Typography
                          key={idx}
                          variant="body1"
                          sx={{
                            backgroundColor: "#e0f7fa",
                            padding: "4px 8px",
                            borderRadius: "4px",
                            fontSize: "0.875rem",
                          }}
                        >
                          {findLabelByID(operation, operations)}
                        </Typography>
                        {editableCategory?.category === category?.category && (
                          <span
                            onClick={() => {
                              handleDeleteOperation(
                                category.category,
                                operation
                              );
                            }}
                          >
                            <Delete />
                          </span>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                {editableCategory &&
                  editableCategory.category === category.category ? (
                  <div>
                    <Select
                      value={selectedOperation || ""}
                      onChange={handleOperationChange}
                      size="small"
                      displayEmpty
                      sx={{
                        minWidth: 80,
                        height: 30,
                        fontSize: 14,
                        padding: "4px 8px",
                      }}
                    >
                      <MenuItem value="" disabled>
                        Выберите операцию
                      </MenuItem>
                      {operations.map((op) => (
                        <MenuItem key={op.value} value={op.value}>
                          {op.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button
                      onClick={() =>
                        handleAddOperation(category.category, config.id)
                      }
                      disabled={!selectedOperation}
                    >
                      Добавить операцию
                    </Button>
                  </div>
                ) : (
                  <Button onClick={() => handleEditCategory(category)}>
                    Редактировать категорию
                  </Button>
                )}
                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  const renderAdministarationConfig = (config) => (
    <div key={config.id} style={renderConfigStyles(config)}>
      <div className="category-header-expand">
        <h3>{configNamesInRussian[config.name]}</h3>{" "}
        <Button onClick={() => handleToggleExpand(config.id)}>
          {expandedConfig === config.id ? "Скрыть" : "Развернуть"}
        </Button>
      </div>
      {expandedConfig === config.id && (
        <div>
          <div>
            {Object.keys(config.data).map((key) => (
              <div className="config-item" key={key}>
                <strong>{findLabelByID(key, locations)}:</strong>
                {editMode === config.id ? (
                  <Select
                    size="small"
                    value={config.data[key]}
                    onChange={(e) =>
                      handleConfigChange(config.id, key, e.target.value)
                    }
                    sx={{
                      minWidth: 80,
                      height: 30,
                      fontSize: 14,
                      padding: "4px 8px",
                    }}
                  >
                    {activeUsersSelection(users, activeUsers)}
                  </Select>
                ) : (
                  <span>{findLabelByID(config.data[key], users)}</span>
                )}
              </div>
            ))}
            <Button onClick={() => handleEditToggle(config.id)}>
              {editMode === config.id ? (
                <span
                  onClick={() => {
                    handleSaveCategory(config.id);
                  }}
                >
                  Сохранить
                </span>
              ) : (
                "Редактировать"
              )}
            </Button>
          </div>
          <div>{renderConfigDetails("administration")}</div>
        </div>
      )}
    </div>
  );

  const renderDefaultTeacherConfig = (config) => (
    <div key={config.id} style={renderConfigStyles(config)}>
      <div className="category-header-expand">
        <h3>{configNamesInRussian[config.name]}</h3>{" "}
        <Button onClick={() => handleToggleExpand(config.id)}>
          {expandedConfig === config.id ? "Скрыть" : "Развернуть"}
        </Button>
      </div>
      {expandedConfig === config.id && (
        <div>
          <div>
            <div className="config-item">
              {editMode === config.id ? (
                <Select
                  size="small"
                  value={config.data.user}
                  onChange={(e) =>
                    handleConfigChange(config.data.type, "key", e.target.value)
                  }
                  sx={{
                    minWidth: 80,
                    height: 30,
                    fontSize: 14,
                    padding: "4px 8px",
                  }}
                >
                  {activeUsersSelection(users, activeUsers)}
                </Select>
              ) : (
                <span>{findLabelByID(config.data.user, users)}</span>
              )}
            </div>

            <Button onClick={() => handleEditToggle(config.id)}>
              {editMode === config.id ? (
                <span
                  onClick={() => {
                    handleSaveCategory(config.id);
                  }}
                >
                  Сохранить
                </span>
              ) : (
                "Редактировать"
              )}
            </Button>
          </div>
          <div>{renderConfigDetails("default_teacher")}</div>
        </div>
      )}
    </div>
  );


  
// Компонент для zoom
const renderZoomLinkTab = () => {
  // Сортируем группы по алфавиту (по label)
  const sortedGroups = entities.group ? [...entities.group].sort((a, b) => a.label.localeCompare(b.label)) : [];
  return (
    <div style={{ border: "1px solid #ccc", padding: "15px", marginBottom: "10px", backgroundColor: "#fff" }}>
      <div className="category-header-expand" onClick={() => setIsZoomTabExpanded(!isZoomTabExpanded)}>
        <h3>Ссылка на Zoom</h3>
        <Button>{isZoomTabExpanded ? "Скрыть" : "Развернуть"}</Button>
        {isZoomTabExpanded && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            size="small"
            sx={{ scale: "0.5" }}
            onClick={(e) => {
              e.stopPropagation();
              setIsAddingNewConfig(true);
            }}
          >
            <Add />
          </Button>
        )}
      </div>

      {isZoomTabExpanded && (
        <div>
          {/* Поля для новой конфигурации */}
          {isAddingNewConfig && (
            <div style={{ marginTop: "10px" }}>
              <TextField
                fullWidth
                label="Ссылка на Zoom"
                value={newZoomConfig.link}
                onChange={(e) => setNewZoomConfig((prev) => ({ ...prev, link: e.target.value }))}
                placeholder="Введите ссылку на Zoom"
              />
              <FormControl fullWidth style={{ marginTop: "10px" }}>
                <InputLabel>Выберите группу</InputLabel>
                <Select
                  value={newZoomConfig.group_id || ""}
                  onChange={(e) => setNewZoomConfig((prev) => ({ ...prev, group_id: e.target.value }))}
                  label="Выберите группу"
                >
                  {sortedGroups.map((group) => (
                    <MenuItem key={group.value} value={group.value}>
                      {group.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleAddZoomLink(newZoomConfig.link, newZoomConfig.group_id);
                    setNewZoomConfig({ link: "", group_id: null });
                    setIsAddingNewConfig(false);
                  }}
                >
                  Сохранить
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setIsAddingNewConfig(false)}
                >
                  Отмена
                </Button>
              </div>
            </div>
          )}

          {/* Существующие конфигурации */}
          {configList
            .filter((config) => config.name === "zoom_params")
            .map((config) => (
              <ZoomConfigItem
                key={config.id}
                config={config}
                onConfigChange={handleConfigChange}
                onEditToggle={handleEditToggle}
                onSaveZoomLink={handleSaveZoomLink}
                onDeleteZoomLink={handleDeleteZoomLink}
                isEditMode={editMode}
                groupList={sortedGroups} // Передаём отсортированные группы
              />
            ))}
        </div>
      )}
    </div>
  );
};
const ZoomConfigItem = React.memo(({ config, onConfigChange, onEditToggle, onSaveZoomLink, isEditMode, groupList }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(config.data.group_id || "");
  const [link, setLink] = useState(config.data.link || "");

  useEffect(() => {
    setSelectedGroupId(config.data.group_id || "");
  }, [config.data.group_id]);

  useEffect(() => {
    setLink(config.data.link || "");
  }, [config.data.link]);

  useEffect(() => {
    if (isEditMode === config.id) {
      setIsExpanded(true);
    }
  }, [isEditMode, config.id]);

  // Находим название группы по selectedGroupId
  const groupData = groupList.find((group) => group.value === selectedGroupId);

  return (
    <div style={{ border: "1px solid #ccc", padding: "15px", marginBottom: "10px", backgroundColor: config.id === isEditMode ? "#f9f9f9" : "#fff" }}>
      <div className="category-header-expand">
        <Typography variant="body1">
          {groupData ? groupData.label : "Группа не выбрана"}
        </Typography>
        <Button onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? "Скрыть" : "Развернуть"}
        </Button>
      </div>

      {isExpanded && (
        <div style={{ marginLeft: "20px" }}>
          <TextField
            fullWidth
            label="Ссылка на Zoom"
            value={link}
            onBlur={(e) => onConfigChange(config.id, "link", e.target.value)}
            onChange={(e) => setLink(e.target.value)}
            disabled={isEditMode !== config.id}
            placeholder="Введите ссылку на Zoom"
          />
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <InputLabel>Выберите группу</InputLabel>
            <Select
              value={selectedGroupId}
              onChange={(e) => {
                setSelectedGroupId(e.target.value);
                onConfigChange(config.id, "group_id", e.target.value);
              }}
              label="Выберите группу"
              disabled={isEditMode !== config.id}
            >
              {groupList.map((group) => (
                <MenuItem key={group.value} value={group.value}>
                  {group.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <Button
              onClick={() => onEditToggle(config.id)}
              variant="contained"
              color="primary"
            >
              {isEditMode === config.id ? (
                <span onClick={() => onSaveZoomLink(config.id, link, selectedGroupId)}>
                  Сохранить
                </span>
              ) : (
                "Редактировать"
              )}
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<Delete />}
              onClick={() => handleDeleteZoomLink(config.id)}
            >
              Удалить
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
  // const responsibilityConfigToRender = responsabilityConfigs.length > 0 ? responsabilityConfigs[0] : null;
  // console.log("respConf", responsabilityConfigs)

  // const [responsabilityConfigs, setResponsabilityConfigs] = React.useState([])

  // code will now render updated responsibility configurations correctly,
  // as responsabilityConfigs will always reflect the latest changes in configList!!!!!

  // React.useEffect(() => {
  //   const finded = configList.filter(
  //     (item) => item.name === "responsibility"
  //   );
  //   setResponsabilityConfigs(finded)
  // }, [configList])

  return (
    <div>
      {responsabilityConfigs.length > 0 &&
        renderResponsabilityConfigs(responsabilityConfigs)}
      {supportConfigs.length > 0 && renderSupportConfigs(supportConfigs)}
      {renderZoomLinkTab()} 
      {/* {holidaysConfigs.length > 0 && renderHolidaysConfigs(holidaysConfigs)} */}
      <RenderHolidaysConfigs
        holidayConfig={holidaysConfigs}
        handleConfigChange={handleConfigChange}
        editMode={editMode}
        handleEditToggle={handleEditToggle}
        handleSaveCategory={handleSaveCategory}
        locations={locations}
        configNamesInRussian={configNamesInRussian}
        entities={entities}
      />
      {configList
  .filter((config) => config.name !== "responsibility"&& config.name !== "zoom_params")
  .map((config) => {
    switch (config.name) {
      case "category":
        return renderCategoryConfig(config);
      case "default_teacher":
        return renderDefaultTeacherConfig(config);
      case "administration":
        return renderAdministarationConfig(config);
      
      default:
        return null;
    }
  })}
    </div>
  );
};

export default ConfigsView;
