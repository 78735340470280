import { Backdrop, CircularProgress, Tab, Tabs, Box, Typography } from "@mui/material";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import dayjs from "dayjs";
import { React, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import Journal from "../../components/Journal/Journal";
import {
  fetchJournal,
  fetchSupportHistory,
  getError,
  checkZoom, // Импортируем экшен для получения ссылки на Zoom
} from "../../redux/actions/journalActions";
import "../../scss/index.scss";
import { journalApi } from "../../utils/api";
import Balance from "../../components/Journal/Balance";
import { setPageTitle } from "../../redux/actions/globalActions";
import Support from "../../components/Journal/Support";

// eslint-disable-next-line react-hooks/rules-of-hooks

function DairyContainer({ ...props }) {
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const userData = useSelector(({ user }) => user);
  const error = useSelector(({ journal }) => journal.error);
  const zoomLink = useSelector(({ journal }) => journal.zoomData); // Получаем ссылку на Zoom из Redux
  const loadingZoom = useSelector(({ journal }) => journal.loadingZoom); // Получаем состояние загрузки Zoom
  const errorZoom = useSelector(({ journal }) => journal.errorZoom); // Получаем ошибку Zoom

  const {
    loadingJournal,
    loadingHistory,
    getJournal,
    title,
    setTitle,
    getSupportHistory,
    supportHistory,
    entities,
    fetchZoom, // Используем переименованный экшен
  } = props;

  const [balance, setBalance] = useState(null);
  const [showBalance, setShowBalance] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const today = dayjs().format("YYYY-MM-DD");
    getJournal({
      date: today,
    });
  }, [getJournal]);

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  useEffect(() => {
    if (userData.permissions.includes("personal_balance.view")) {
      journalApi.getBalance().then((data) => setBalance(data));
    }
  }, [userData]);

  useEffect(() => {
    getSupportHistory();
  }, [getSupportHistory]);

  useEffect(() => {
    if (activeTab === 3) {
      fetchZoom(); // Используем переименованный экшен
    }
  }, [activeTab, fetchZoom]);

  useEffect(() => {
    console.log("Данные с сервера для Zoom: ", zoomLink); // Логируем полученные данные
  }, [zoomLink]);
  
  const useStyles = makeStyles((theme) => ({
    tab: {
      textDecoration: "none",
      color: " rgba(0, 0, 0, 0.6)",
      opacity: 0.6,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    activeTab: {
      textDecoration: "underline!important",
      color: "#2999DD!important",
    },
    zoomLink: {
      marginTop: "20px",
      textAlign: "center",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div className="content-component__wrapper">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4 className="table__title">{title}</h4>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="transparent"
          >
            <Tab
              size="small"
              label="дневник"
              value={0}
              onClick={() => {
                setShowBalance(false);
              }}
              className={`${classes.tab} ${
                activeTab === 0 ? classes.activeTab : ""
              }`}
            />
            <Tab
              label="баланс"
              size="small"
              value={1}
              onClick={() => {
                setShowBalance(true);
              }}
              className={`${classes.tab} ${
                activeTab === 1 ? classes.activeTab : ""
              }`}
            />
            <Tab
              label="техподдержка"
              size="small"
              value={2}
              className={`${classes.tab} ${
                activeTab === 2 ? classes.activeTab : ""
              }`}
            />
            <Tab
            label={
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Online </Typography>
                <PlayArrowIcon fontSize="small" style={{ marginLeft: 5 }} />
              </Box>
            }
            size="small"
            value={3}
            className={`${classes.tab} ${activeTab === 3 ? classes.activeTab : ""}`}
          />
          </Tabs>
        </div>
        {error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            {" "}
            {error.includes("does`t have a children") ? (
              <>У вас нет детей</>
            ) : (
              <>Нет данных</>
            )}{" "}
          </div>
        )}
        {loadingJournal || loadingHistory || appGlobalState.loading ? (
          <Backdrop open={!appGlobalState.loading} sx={backdropStyles}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <>
            {activeTab === 0 && (
              <Journal {...props} getJournal={getJournal} balance={balance} />
            )}
            {activeTab === 1 && <Balance balance={balance} />}
            {activeTab === 2 && (
              <Support supportHistory={supportHistory} entities={entities} />
            )}
            {activeTab === 3 && (
  <div className={classes.zoomLink}>
    {loadingZoom && <CircularProgress />}
    {errorZoom && <div>Ошибка: {errorZoom}</div>}
    {!loadingZoom && !errorZoom && Array.isArray(zoomLink) && zoomLink.length > 0 && (
      <>
        {zoomLink.map((group) => {
          // Ищем группу в entities, сопоставляя group_id с value
          const groupDetails = Object.values(entities)
            .flat() // Преобразуем все массивы в единый массив
            .find(entity => entity.value === group.group_id); // Сравниваем value с group_id

          return (
            <div key={group.group_id} style={{ textAlign: "left", marginBottom: "10px" }}>
              <Typography variant="body1" style={{ display: "inline", marginRight: "10px" }}>
                {groupDetails ? groupDetails.label : group.group_id}
              </Typography>
              <a
                href={group.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "left", display: "inline", marginLeft: "10px" }}
              >
                {group.link}
              </a>
            </div>
          );
        })}
      </>
    )}
    {zoomLink && zoomLink.length === 0 && !loadingZoom && !errorZoom && (
      <Typography variant="body1">Нет доступных ссылок на Zoom</Typography>
    )}
  </div>
)}



          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loadingJournal: state.journal.loadingJournal,
  loadingHistory: state.journal.loadingHistory,
  journal: state.journal.payload,
  entities: state.entitiesTypes,
  supportHistory: state.journal.supportHistory,
  zoomData: state.journal.zoomData, // Ссылка на Zoom
  loadingZoom: state.journal.loadingZoom, // Состояние загрузки
  errorZoom: state.journal.errorZoom, // Ошибка
});

const mapDispatchToProps = (dispatch) => ({
  getJournal: (data) => {
    dispatch(fetchJournal(data)).catch((e) => {
      dispatch(getError(e));
    });
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
  getSupportHistory: () => {
    dispatch(fetchSupportHistory());
  },
  fetchZoom: () => {
    dispatch(checkZoom()); // Переименованный экшен
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DairyContainer);