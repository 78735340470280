import { appointmentsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const getTeachersPlan = (data) => ({
  type: "GET_TEACHERS_PLAN",
  payload: data,
  loading: false,
});

export const loadingAppointments = () => ({
  type: "LOADING_APPOINTMENTS",
  loading: true,
});

export const addAppointments = (data) => ({
  type: "ADD_APPOINTMENTS",
  payload: data,
  loading: false,
});

export const addSubstitution = (data) => ({
  type: "ADD_SUBSTITUTION",
  payload: data,
  loading: false,
});

export const updateAppointments = (data) => ({
  type: "UPDATE_APPOINTMENTS",
  payload: data,
  loading: false,
});
export const updateSubstitution = (data) => ({
  type: "UPDATE_SUBSTITUTION",
  payload: data,
  loading: false,
});
export const deleteAppointments = (id) => ({
  type: "REMOVE_APPOINTMENTS",
  payload: id,
  loading: false,
});
export const deleteSubstitution = (id) => ({
  type: "REMOVE_SUBSTITUTION",
  payload: id,
  loading: false,
});

export const cancelAppointments = () => ({
  type: "CANCEL_APPOINTMENTS",
  payload: null,
  loading: false,
});

export const fetchTeachersPlan = (params) => (dispatch) => {
  dispatch(loadingAppointments());
  appointmentsApi
    .get(params)
    .then(({ data }) => {
      console.log("Fetched data:", data); // Проверьте, есть ли `subject_substitute`
      dispatch(getTeachersPlan(data));
    });
};

export const fetchTeachersPlanHidden = (params) => (dispatch) => appointmentsApi.get(params).then(({ data }) => {
  dispatch(getTeachersPlan(data));
  return data;
});
export const postAppointments = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .add(obj)
    .then(({ data }) => {
      console.log("add", data);
      notification.success("Назначение создано");
      dispatch(addAppointments(data));
      return data;
    })
    .catch((err) => {
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};
export const postSubstitution = (obj) => (dispatch) => {
  // Проверка: если выбрана замена, но предмет замены пустой
  if (obj.is_substitute && (!obj.subject_substitute || obj.subject_substitute.trim() === "")) {
    alert("Ошибка: Для замены необходимо указать предмет замены");
    return Promise.reject(new Error("Поле 'Предмет замены' не заполнено"));
  }

  dispatch(loadingAppointments());
  return appointmentsApi
    .add(obj)
    .then(({ data }) => {
      notification.success("Замена назначена");
      dispatch(addSubstitution(data));
      return data;
    })
    .catch((err) => {
      console.error(err);
      alert(err.response?.data?.message || "Ошибка: Не удалось назначить замену");
      dispatch(cancelAppointments());
    });
};

export const updAppointments = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .update(obj)
    .then(({ data }) => {
      console.log("Response from server:", data); // Проверьте, что `subject_substitute` присутствует
      dispatch(updateAppointments(data));
    })
    .catch((err) => {
      console.error(err);
      dispatch(cancelAppointments());
    });
};

export const updSubstitution = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .update(obj)
    .then(({ data }) => {
      notification.success("Данные изменены");
      dispatch(updateSubstitution(data));
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};

export const removeAppointments = (id) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi.remove(id).then(() => {
    notification.success("Назначение удалено");
    console.log("id", id);
    dispatch(deleteAppointments(id));
  });
};

export const removeAppointmentsWithoutReaload = (id) => (dispatch) =>
  appointmentsApi.remove(id).then((response) => {
    if (response.status === 200) {
      notification.success("Назначение удалено");
      dispatch(deleteAppointments(id));
    } else {
      notification.error("Назначение не удалено");
    }
    return response;
  });

export const removeSubstitute = (id) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi.remove(id).then(() => {
    notification.success("Замена удалена");
    console.log("id", id);
    dispatch(deleteSubstitution(id));
  });
};

// we can use this function to update appointments in classScheduleView
//  that the calendar with schedule does not reload and we handle the update in the background
export const updAppointmentInSchedule = (obj) => (dispatch) =>
  appointmentsApi
    .update(obj)
    .then(({ data }) => {
      notification.success("Данные расписания изменены");
      dispatch(updateAppointments(data));
      return data; // for using in classScheduleView
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
      return null;
    });

export const postAppointmentsInSchedule = (obj) => (dispatch) =>
  appointmentsApi
    .add(obj)
    .then(({ data }) => {
      console.log("add", data);
      notification.success("Назначение создано");
      dispatch(addAppointments(data));
      return data; // for using in classScheduleView
    })
    .catch((err) => {
      alert(err.response.data.message);
      dispatch(cancelAppointments());
      return null;
    });


    export const undoAction = () => async (dispatch, getState) => {
      // Получаем текущее состояние из Redux
      const state = getState().appointmentsAndSubstitute;
    
      // Если история пуста, выходим из функции
      if (state.history.length === 0) return;
    
      // Берём последнее действие из истории
      const lastAction = state.history[state.history.length - 1];
    
      // Создаём обновлённую историю, удаляя последнее действие
      const updatedHistory = state.history.slice(0, -1);
    
      try {
        // Если последнее действие было добавлением (type === "add")
        if (lastAction.type === "add") {
          // Отменяем добавление: удаляем урок через API
          await appointmentsApi.remove({ appointment: lastAction.lesson.id });
    
          // Диспатчим действие для обновления состояния Redux
          dispatch({
            type: "UNDO_ADD",
            payload: { lessonId: lastAction.lesson.id, updatedHistory },
          });
    
          // Уведомляем пользователя об успешной отмене
          notification.success("Добавление отменено");
        }
    
        // Если последнее действие было редактированием (type === "edit")
        else if (lastAction.type === "edit") {
          // Отменяем редактирование: восстанавливаем оригинальный урок через API
          await appointmentsApi.update({ appointment: lastAction.originalLesson });
    
          // Диспатчим действие для обновления состояния Redux
          dispatch({
            type: "UNDO_EDIT",
            payload: {
              updatedLesson: lastAction.originalLesson,
              updatedHistory,
            },
          });
    
          // Уведомляем пользователя об успешной отмене
          notification.success("Изменение отменено");
        }
    
        // Если последнее действие было удалением (type === "remove")
        else if (lastAction.type === "remove") {
          // Восстанавливаем удалённый урок через API
          const { date, duration, group_id, subject, user_id } = lastAction.lesson;
          const response = await appointmentsApi.add({
            appointment: { date, duration, group_id, subject, user_id },
          });
    
          // Диспатчим действие для обновления состояния Redux
          dispatch({
            type: "UNDO_REMOVE",
            payload: {
              serverLesson: response.data,
              updatedHistory,
            },
          });
    
          // Уведомляем пользователя об успешном восстановлении
          notification.success("Назначение восстановлено");
        }
      } catch (error) {
        // Логируем ошибку и уведомляем пользователя
        console.error("Error undoing action:", error);
        notification.error("Не удалось отменить действие");
      }
    };

    export const redoAction = () => async (dispatch, getState) => {
      // Получаем текущее состояние из Redux
      const state = getState().appointmentsAndSubstitute;
    
      // Если стек redo пуст, выходим из функции
      if (state.redoStack.length === 0) return;
    
      // Берём последнее отменённое действие из стека redo
      const lastUndoneAction = state.redoStack[state.redoStack.length - 1];
    
      // Создаём обновлённый стек redo, удаляя последнее действие
      const updatedRedoStack = state.redoStack.slice(0, -1);
    
      try {
        // Если последнее отменённое действие было добавлением (type === "add")
        if (lastUndoneAction.type === "add") {
          // Повторно добавляем урок через API
          const { date, duration, group_id, subject, user_id } = lastUndoneAction.lesson;
          const response = await appointmentsApi.add({
            appointment: { date, duration, group_id, subject, user_id },
          });
    
          // Диспатчим действие для обновления состояния Redux
          dispatch({
            type: "REDO_ADD",
            payload: {
              serverLesson: response.data,
              updatedRedoStack,
            },
          });
    
          // Уведомляем пользователя об успешном повторном добавлении
          notification.success("Назначение повторно добавлено");
        }
    
        // Если последнее отменённое действие было редактированием (type === "edit")
        else if (lastUndoneAction.type === "edit") {
          // Повторно применяем редактирование через API
          await appointmentsApi.update({ appointment: lastUndoneAction.updatedLesson });
    
          // Диспатчим действие для обновления состояния Redux
          dispatch({
            type: "REDO_EDIT",
            payload: {
              updatedLesson: lastUndoneAction.updatedLesson,
              updatedRedoStack,
            },
          });
    
          // Уведомляем пользователя об успешном повторном изменении
          notification.success("Изменение повторно применено");
        }
    
        // Если последнее отменённое действие было удалением (type === "remove")
        else if (lastUndoneAction.type === "remove") {
          // Повторно удаляем урок через API
          await appointmentsApi.remove({ appointment: lastUndoneAction.lesson.id });
    
          // Диспатчим действие для обновления состояния Redux
          dispatch({
            type: "REDO_REMOVE",
            payload: {
              lessonId: lastUndoneAction.lesson.id,
              updatedRedoStack,
            },
          });
    
          // Уведомляем пользователя об успешном повторном удалении
          notification.success("Назначение повторно удалено");
        }
      } catch (error) {
        // Логируем ошибку и уведомляем пользователя
        console.error("Error redoing action:", error);
        notification.error("Не удалось повторить действие");
      }
    };