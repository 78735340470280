const initialState = {
  payload: [],
  supportHistory: [],
  loadingJournal: false,
  loadingHistory: false,
  zoomData: null,       // Новое поле для данных Zoom
  loadingZoom: false,   // Новое поле для состояния загрузки Zoom
  errorZoom: null,      // Новое поле для ошибки Zoom
};

const sortJournal = (source) => {
  // eslint-disable-next-line array-callback-return
  source.map((item) => {
    const key = Object.keys(item);
    item[key].sort((a, b) => (a.date > b.date ? 1 : -1));
    item[key].map((obj) =>
      obj.lesson.sort((a, b) => (a.date > b.date ? 1 : -1))
    );
  });
  return source;
};

const dairyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_JOURNAL":
      return {
        ...state,
        payload: sortJournal(action.payload),
        loadingJournal: action.loading,
      };
    case "LOADING_JOURNAL":
      return {
        ...state,
        loadingJournal: action.loading,
      };
    case "LOADING_SUPPORT_HISTORY":
      return {
        ...state,
        loadingHistory: action.loading,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload.response.data.message,
      };
    case "SET_SUPPORT_HISTORY":
      return {
        ...state,
        supportHistory: action.payload,
        loadingHistory: action.loading,
      };
    // Новые кейсы для Zoom
    case "CHECK_ZOOM_REQUEST":
      return {
        ...state,
        loadingZoom: true,
        errorZoom: null,
      };
    case "CHECK_ZOOM_SUCCESS":
      return {
        ...state,
        loadingZoom: false,
        zoomData: action.payload || [],
      };
    case "CHECK_ZOOM_FAILURE":
      return {
        ...state,
        loadingZoom: false,
        errorZoom: action.error,
      };
    default:
      return state;
  }
};

export default dairyReducer;