const initialState = {
  payload: [],
  loading: false,
  zoomLink: "",
  error: null,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONFIGS":
      return {
        ...state,
        payload: action.payload,
        loading: action.loading,
      };
    case "UPDATE_CONFIG":
      return {
        ...state,
        payload: state.payload.map((item) =>
          item.id === action.payload.id
            ? { ...item, ...action.payload.data }
            : item
        ),
        loading: action.loading,
      };
    case "LOADING_CONFIGS":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_ZOOM_LINK_REQUEST":
      return { ...state, loading: true };
    case "FETCH_ZOOM_LINK_SUCCESS":
      return { ...state, loading: false, zoomLink: action.payload.link };
    case "FETCH_ZOOM_LINK_FAILURE":
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export default settingsReducer;