import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import "./SimpleTabs.scss";

const SimpleTabs = ({ locations, onLocationChange, selectedLocation }) => {
  const [selectedTab, setSelectedTab] = React.useState(selectedLocation || locations[0]);
  const entitiesTypes = useSelector((state) => state.entitiesTypes.location);

  // Синхронизация при монтировании и изменении selectedLocation
  React.useEffect(() => {
    const savedLocation = localStorage.getItem("selectedLocation");
    const initialLocation = savedLocation || selectedLocation || locations[0];
    
    setSelectedTab(initialLocation);
    if (initialLocation !== selectedLocation) {
      onLocationChange(initialLocation);
    }
  }, [selectedLocation, locations]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    onLocationChange(newValue);
    localStorage.setItem("selectedLocation", newValue);
  };

  if (!entitiesTypes || !locations) {
    return <div>Загрузка филиалов...</div>;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        className="location-tabs"
      >
        {locations?.map((child, index) => (
          <Tab
            label={`${findLabelByID(child, entitiesTypes)}`}
            key={index}
            value={child}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default SimpleTabs;