/* eslint-disable consistent-return */
import React, { useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ru"; // Import the Russian locale for dayjs
import "./AppointmentsView.css";
import "../../scss/index.scss";
import { ru } from "date-fns/locale";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  dateTimeFormatterFunc,
  dateTimeSetterFunc,
} from "../../utils/helpers/dataGridFormatters";
import DataGrid from "../DataGrid/DataGrid";
import "dayjs/locale/nl";
import activeUsersSelection from "../../utils/helpers/activeUserSelection";
import SimpleYesNo from "../ReusableSimpleYesNo/ReusableSimpleYesNo";
import SimpleTabs from "../TabsComponent/SimpleTabs";
import { log } from "../../utils/helpers/tracker";

// Set the locale globally for dayjs
dayjs.locale("ru");

function AppointmentsView({
  permissions,
  entitiesTypes,
  addAppointment,
  updateAppointment,
  removeAssigned,
  removeSub,
  locations,
  getTeachersPlan,
  updAppointments,
  ...props
}) {
  const initialSubstituteValues = {
    date: "",
    user_id: "",
    group_id: "",
    subject: "",
    duration: "",
  };

  const initiaReverseValues = {
    date: "Дата",
    user_id: "Преподаватель",
    group_id: "Группа",
    subject: "Предмет",
    duration: "Продолжительность",
  };

  const [errorMessage, setErrorMessage] = useState("");

  const [newSubstitute, setSubstitute] = React.useState(
    initialSubstituteValues
  );
  const [selectedLocation, setSelectedLocation] = React.useState(locations[0]);
  const handleLocationChange = (value) => {
    setSelectedLocation(value);
    if (value) {
      getTeachersPlan(value);
    }
  };

  const dateSetterFunc = (value) => {
    if (value) {
      return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    }
    const today = new Date();
    return dayjs(today).format("YYYY-MM-DD HH:mm:ss");
  };

  const activeTeachers = entitiesTypes.active_users;
  const teachers = entitiesTypes.user?.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );
  const groupsGlobal = entitiesTypes.group?.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );
  const subjects = entitiesTypes.subject;
  const duration = [
    { value: "0:45", label: "0:45" },
    { value: "1:00", label: "1:00" },
    { value: "1:30", label: "1:30" },
    { value: "2:15", label: "2:15" },
    { value: "3:00", label: "3:00" },
  ];

  const getLabelFromEntities = (id, entitite) => {
    if (!id) {
      return;
    }
    if (entitite) {
      const obj = entitite.find((item) => item.value === id);
      return obj?.label || `UNKNOWN/DELETED id: ${id}`;
    }
  };

  const handleSubstituteInput = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "date") {
      setSubstitute({
        ...newSubstitute,
        [name]: dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
      });
      console.log(newSubstitute);
    } else {
      setSubstitute({
        ...newSubstitute,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.value) {
      setErrorMessage("");
    }
  };

  const handleAppointmentsSubmit = () => {
    // Проверка: если выбрана замена, но предмет замены пустой
    if (newSubstitute.is_substitute && (!newSubstitute.subject_substitute || newSubstitute.subject_substitute.trim() === "")) {
      setErrorMessage("Для замены необходимо указать предмет замены");
      return;
    }

    const appointmentData = {
      ...newSubstitute,
    };

    addAppointment({
      appointment: appointmentData,
    })
      .then(() => {
        setSubstitute(initialSubstituteValues); // Сброс состояния
        getTeachersPlan(selectedLocation); // Обновление данных
        setErrorMessage(""); // Очистка сообщения об ошибке
      })
      .catch((err) => {
        console.error("Ошибка при создании записи:", err);
        setErrorMessage("Не удалось создать запись");
      });
  };

  const handleSaveAppointment = (appointmentData) => {
    // Проверка: если выбрана замена, но предмет замены пустой
    if (
      appointmentData.is_substitute &&
      (!appointmentData.subject_substitute || appointmentData.subject_substitute.trim() === "")
    ) {
      alert("Ошибка: Для замены необходимо указать предмет замены");
      return Promise.reject(new Error("Поле 'Предмет замены' не заполнено"));
    }

    return updAppointments(appointmentData)
      .then(() => {
        alert("Успех: Данные успешно сохранены");
      })
      .catch((err) => {
        alert("Ошибка: Не удалось сохранить данные");
        console.error("Ошибка при сохранении:", err);
      });
  };

  const [isStatusCheckModalOpen, setStatusCheckModalOpen] = useState(false);
  const [appointment, saveAppointment] = React.useState({});

  const openCheckModal = () => {
    setStatusCheckModalOpen(true);
  };
  const closeCheckModal = () => {
    setStatusCheckModalOpen(false);
    saveAppointment({});
  };
  const handleCheckModalConfirm = (params) => {
    removeSub({
      appointment: appointment.id,
      is_substitute: "remove",
    });
    closeCheckModal();
  };

  const handleDeleteAppointment = (params) => {
    if (params.row.is_substitute) {
      return (
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            openCheckModal(params);
            saveAppointment(params.row);
          }}
        >
          {" "}
          Удалить{" "}
        </Button>
      );
    }
  };

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "date",
      headerName: "Дата/Время",
      type: "dateTime",
      editable: true,
      flex: 0.5,
      valueFormatter: dateTimeFormatterFunc,
      valueSetter: (params) => ({
        ...params.row,
        date: dateSetterFunc(params.value),
      }),
    },
    {
      field: "user_id",
      headerName: "Преподаватель",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: entitiesTypes.user,
      valueFormatter: (params) => getLabelFromEntities(params.value, teachers),
      renderEditCell: (params) => (
        <Select
          value={params.value}
          onChange={(e) =>
            params.api.setEditCellValue({ ...params, value: e.target.value })
          }
        >
          {activeUsersSelection(teachers, activeTeachers)}
        </Select>
      ),
    },
    {
      field: "group_id",
      headerName: "Группа",
      editable: true,
      type: "singleSelect",
      valueOptions: entitiesTypes.group,
      flex: 1,
      valueFormatter: (params) =>
        getLabelFromEntities(params.value, groupsGlobal),
    },
    {
      field: "subject",
      headerName: "Предмет",
      flex: 0.7,
      type: "singleSelect",
      valueOptions: entitiesTypes.subject,
      editable: true,
      valueFormatter: (params) => getLabelFromEntities(params.value, subjects),
    },
    {
      field: "duration",
      headerName: "Продолжительность",
      type: "singleSelect",
      editable: true,
      valueOptions: duration,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Статус",
      editable: true,
      flex: 1,
      cellClassName: (params) => {
        const userActivity = params.row.status;
        if (userActivity)
          return clsx("super-app", {
            positive: userActivity === "просрочено",
            negative: userActivity === "запланировано",
          });
      },
    },
    {
      field: "is_substitute",
      headerName: "Замена",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: entitiesTypes.user || "",
      valueFormatter: (params) => getLabelFromEntities(params.value, teachers),
      renderEditCell: (params) => (
        <Select
          fullWidth
          value={params.value}
          onChange={(e) =>
            params.api.setEditCellValue({ ...params, value: e.target.value })
          }
        >
          {activeUsersSelection(teachers, activeTeachers)}
        </Select>
      ),
    },
    {
      field: "subject_substitute",
      headerName: "Предмет замены*",
      flex: 0.7,
      type: "singleSelect",
      valueOptions: entitiesTypes.subject,
      editable: true,
      valueFormatter: (params) => getLabelFromEntities(params.value, subjects),
      cellClassName: (params) =>
        params.row.is_substitute && !params.value ? "invalid-cell" : "",
      renderEditCell: (params) => (
        <Select
          fullWidth
          value={params.value || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            params.api.setEditCellValue({ ...params, value: newValue });
          }}
        >
          <MenuItem value="" disabled>
            Выберите предмет замены
          </MenuItem>
          {subjects.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "Удалить замену",
      headerName: "Удалить замену",
      sortable: false,
      renderCell: (params) => handleDeleteAppointment(params),
    },
  ];

  return (
    <>
      <SimpleTabs
        locations={locations}
        onLocationChange={handleLocationChange}
        selectedLocation={selectedLocation}
      />
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start-end",
            flexWrap: "wrap",
            margin: "10px 25px 10px 25px",
          }}
          component="div"
        >
          <TextField
            id="outlined-data"
            label="Дата"
            name="date"
            size="small"
            locale={ru}
            type="datetime-local"
            onChange={handleSubstituteInput}
            value={newSubstitute.date}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              margin: "5px",
            }}
          />

          <FormControl>
            <TextField
              id="outlined-select-user"
              select
              name="user_id"
              label="Преподаватели"
              size="small"
              value={newSubstitute.user_id}
              onChange={handleSubstituteInput}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "150px",
                margin: "5px",
              }}
            >
              {activeUsersSelection(teachers, activeTeachers)}
            </TextField>
          </FormControl>

          <FormControl>
            <TextField
              name="group_id"
              select
              size="small"
              id="outlined-group_id"
              value={newSubstitute.group_id}
              label="Группа"
              // eslint-disable-next-line jsx-a11y/aria-proptypes
              aria-sort="label"
              onChange={handleSubstituteInput}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "150px",
                margin: "5px",
              }}
            >
              {groupsGlobal ? (
                groupsGlobal.map((item) => (
                  <MenuItem key={uuidv4()} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={uuidv4()} value="none">
                  none
                </MenuItem>
              )}
            </TextField>
          </FormControl>
          <FormControl>
            <TextField
              name="subject"
              select
              size="small"
              id="outlined-subject"
              value={newSubstitute.subject}
              label="Предмет"
              // eslint-disable-next-line jsx-a11y/aria-proptypes
              aria-sort="label"
              onChange={handleSubstituteInput}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "150px",
                margin: "5px",
              }}
            >
              {subjects ? (
                subjects.map((item) => (
                  <MenuItem key={uuidv4()} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={uuidv4()} value="none">
                  none
                </MenuItem>
              )}
            </TextField>
          </FormControl>

          <FormControl>
            <TextField
              id="outlined-select-user"
              select
              name="duration"
              label="Продолжительность"
              size="small"
              value={newSubstitute.duration}
              onChange={handleSubstituteInput}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "150px",
                margin: "5px",
              }}
            >
              <MenuItem value="0:45">1 x 45</MenuItem>
              <MenuItem value="1:00">1 x 60</MenuItem>
              <MenuItem value="1:30">2 x 45</MenuItem>
              <MenuItem value="2:15">3 x 45</MenuItem>
              <MenuItem value="3:00">4 x 45</MenuItem>
            </TextField>
          </FormControl>

          <Button
            variant="contained"
            onClick={handleAppointmentsSubmit}
            sx={{
              margin: "5px",
              height: "40px",
            }}
          >
            Создать
          </Button>
        </Box>
      </div>
      <Box
        sx={{
          margin: "0 30px",
          color: "red",
          height: "20px",
          display: errorMessage ? "block" : "none",
        }}
      >
        {errorMessage}
      </Box>

      <div className="table__wrapper">
        <Box
          sx={{
            "& .super-app-theme--cell": {
              backgroundColor: "rgba(224, 183, 60, 0.55)",
              color: "#1a3e72",
              fontWeight: "600",
              border: "1px solid #000000",
            },
            "& .super-app.negative": {
              backgroundColor: "rgba(140, 255, 111, 0.747)",
              color: "#1a3e72",
              fontWeight: "600",
              border: "1px solid #000000",
            },
            "& .super-app.positive": {
              backgroundColor: "#fa8e64",
              color: "#1a3e72",
              fontWeight: "600",
              border: "1px solid #000000",
            },
            margin: "0",
          }}
        >
          <DataGrid
            columns={columns}
            entityName="appointment"
            dataGridName="appointment"
            permissions={permissions}
            data={props.appointments}
            removeData={removeAssigned}
            updateData={(data) => {
              const { is_substitute, subject_substitute } = data.appointment;

              // Проверка: если выбрана замена, но предмет замены пустой
              if (is_substitute && (!subject_substitute || subject_substitute.trim() === "")) {
                alert("Ошибка: Для замены необходимо указать предмет замены");
                return; // Прерываем выполнение
              }

              updateAppointment({
                appointment: data.appointment,
              });
            }}
            {...props}
          />
        </Box>
      </div>
      <SimpleYesNo
        isOpen={isStatusCheckModalOpen}
        onClose={closeCheckModal}
        onConfirm={handleCheckModalConfirm}
        content="Вы хотите удалить замену?"
      />
    </>
  );
}

export default AppointmentsView;
