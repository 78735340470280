import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchTeachersPlan,
  postAppointmentsInSchedule,
  postSubstitution,
  removeAppointments,
  removeSubstitute,
  updAppointmentInSchedule,
  updSubstitution,
} from "../../redux/actions/appointmentsActions";
import "../../scss/index.scss";
import { setPageTitle } from "../../redux/actions/globalActions";
import ClassScheduleView from "../../components/ClassSchedule/ClassScheduleView";
import { getUserLocation } from "../../utils/helpers/getDefaultUser";
import SimpleTabs from "../../components/TabsComponent/SimpleTabs";
import AdvicePopup from "../../components/AdvicePopup/AdvicePopup";
import undo from "../../assets/img/undo.jpg";
import redo from "../../assets/img/redo.jpg";

const mapStateToProps = (state) => ({
  loading: state.appointmentsAndSubstitute.loading,
  appointments: state.appointmentsAndSubstitute.appointments,
  globalState: state.globalState,
  globalLoading: state.globalState.loading,
  entitiesTypes: state.entitiesTypes,
  config: state.appointmentsAndSubstitute.config,
  permissions: {
    add: false,
    modify: true,
    remove: true,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getTeachersPlan: (params) => {
    dispatch(fetchTeachersPlan(params));
  },
  addAppointment: (data) => dispatch(postAppointmentsInSchedule(data)),
  updAppointment: (data) => dispatch(updAppointmentInSchedule(data)),
  addSubstitution: (data) => {
    dispatch(postSubstitution(data));
  },
  updateSubstitution: (data) => {
    dispatch(updSubstitution(data));
  },
  removeSub: (id) => {
    dispatch(removeSubstitute(id));
  },
  removeAssigned: (id) => {
    dispatch(removeAppointments(id));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

function ClassSchedulePage({ ...props }) {
  const {
    loading,
    getTeachersPlan,
    title,
    appointments,
    globalLoading,
    config,
    setTitle,
  } = props;

  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocationChange = (value) => {
    setSelectedLocation(value);
  };

  // Устанавливаем title страницы
  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  // Загружаем доступные локации и восстанавливаем выбранную вкладку из localStorage
  useEffect(() => {
    const userLocations = getUserLocation() || [];
    setLocations(userLocations);

    // Проверяем, есть ли сохраненная вкладка в localStorage
    const savedLocation = localStorage.getItem('selectedLocation');
    if (savedLocation) {
      setSelectedLocation(savedLocation);
    } else {
      // Если нет, используем первую локацию
      const firstLocation = userLocations[0] || null;
      setSelectedLocation(firstLocation);
    }
  }, []);

  // Загружаем данные для выбранной локации
  useEffect(() => {
    if (selectedLocation) {
      getTeachersPlan(selectedLocation);
    }
  }, [selectedLocation, getTeachersPlan]);

  // Сохраняем выбранную вкладку в localStorage при изменении
  useEffect(() => {
    if (selectedLocation) {
      localStorage.setItem('selectedLocation', selectedLocation);
    }
  }, [selectedLocation]);

  // Определяем, какой контент отображать
  let content;

  if (loading || globalLoading) {
    content = (
      <Backdrop open sx={backdropStyles}>
        <CircularProgress />
      </Backdrop>
    );
  } else if (appointments.length === 0 || config.length === 0) {
    content = (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "70vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Нет данных для отображения
      </div>
    );
  } else {
    content = (
      <ClassScheduleView
        {...props}
        locations={locations}
        selectedLocation={selectedLocation}
      />
    );
  }

  return (
    <>
      <AdvicePopup>
        <strong>
          {" "}
          &#128274; &#128274;!для возможности редактировать нажмите замок в
          верхней части страницы! &#128274; &#128274; <br />
          <span>
            ------------------------------------------------------------
          </span>
          <br />
        </strong>
        <strong>Выберите нужную локацию и неделю в шапке страницы </strong>
        <br />
        <strong>Редактирование урока: </strong> Дважды кликните на существующий
        урок, чтобы отредактировать его. <br />
        <strong>Создание урока:</strong> Дважды кликните на пустую клетку, чтобы
        создать новый урок. <br />
        <strong>Перемещение урока:</strong> Перетащите урок на другой день или
        время, чтобы автоматически обновить время начала занятия и день. Либо
        укажите другой день/время при редактировании <br />
        <strong>Синхронизация изменений:</strong> Все изменения немедленно
        синхронизируются с сервером. Любые правки, внесённые здесь, будут
        отражены и во вкладке Назначения и замены, и наоборот. Так же в
        Расписание занятий.
        <br />
        <strong>Отмена и повтор действий: </strong>
        Нажмите на <img src={undo} alt="undo_button" width="80px" /> (кнопка
        Undo), чтобы отменить последнее действие, или на{" "}
        <img src={redo} alt="redo_button" width="80px" /> (кнопка Redo), чтобы
        повторить отменённое действие. Эти кнопки помогают легко управлять
        изменениями в расписании, возвращаясь к предыдущему состоянию или
        повторяя недавние действия.
      </AdvicePopup>
      <div className="content-component__wrapper">
        <h4 className="table__title">{title}</h4>
        <SimpleTabs
          locations={locations}
          onLocationChange={handleLocationChange}
          selectedLocation={selectedLocation}
        />
        {content}
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassSchedulePage);
