import { contractApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const setContract = (data) => ({
  type: "SET_CONTRACT",
  payload: data,
  loading: false,
});

export const clearContract = () => ({
  type: "CLEAR_CONTRACT",
});

export const updateContract = (data) => ({
  type: "UPDATE_CONTRACT",
  payload: data,
  loading: false,
});

export const loadingContracts = () => ({
  type: "LOADING_CONTRACT",
  loading: true,
});

export const loadReasons = (data) => ({
  type: "LOAD_REASONS",
  payload: data,
});

export const getAllContracts = (data) => ({
  type: "GET_CONTRACTS",
  payload: data,
  loading: false,
});

export const getContract = (contract_id) => (dispatch) => {
  dispatch(loadingContracts());
  return contractApi.get(contract_id).then(({ data }) => {
    if (data && data.length > 0) {
      dispatch(setContract(data[0].debt));
    } else {
      notification.error("Не удалось получить данные по договору (данные пусты или некорректны)");
    }
    return data;
  }).catch((error) => {
    console.error("Ошибка при загрузке контракта:", error);
    notification.error("Ошибка при загрузке данных контракта");
    return Promise.reject(error);
  });
};

export const getContractToExtend = (contract_id) => (dispatch) => {
  dispatch(loadingContracts());
  return contractApi.get(contract_id).then(({ data }) => {
    if (!data || data.length === 0) {
      notification.error("Не удалось продлить договор (договор не активен)");
      return null;
    }
    dispatch(setContract(data[0].debt));
    return data;
  }).catch((error) => {
    console.error("Ошибка при загрузке данных для продления контракта:", error);
    notification.error("Ошибка при загрузке данных для продления контракта");
    return Promise.reject(error);
  });
};

export const getContracts = () => (dispatch) =>
  contractApi.getAllContracts().then(({ data }) => {
    if (data && data.contracts) {
      dispatch(getAllContracts(data.contracts));
    } else {
      notification.error("Не удалось получить контракты");
    }
    return data;
  }).catch((error) => {
    console.error("Ошибка при загрузке всех контрактов:", error);
    notification.error("Ошибка при загрузке всех контрактов");
    return Promise.reject(error);
  });

export const sendUpdatedContract = (obj) => (dispatch) => {
  dispatch(loadingContracts());
  return contractApi.update({ ...obj }).then(({ data }) => {
    if (data && data.debt_and_credit !== undefined) {
      notification.success("Договор обновлен");
      dispatch(updateContract(data.debt_and_credit));
    } else {
      notification.error("Ошибка обновления контракта: некорректные данные");
    }
    return data;
  }).catch((error) => {
    console.error("Ошибка при обновлении контракта:", error);
    notification.error("Ошибка при обновлении контракта");
    return Promise.reject(error);
  });
};

export const getReasonsList = () => (dispatch) =>
  contractApi.getList().then((reasons) => {
    dispatch(loadReasons(reasons.data));
    return reasons.data;
  }).catch((error) => {
    console.error("Ошибка при получении списка причин:", error);
    notification.error("Ошибка при получении списка причин");
    return Promise.reject(error);
  });