import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import "../../scss/index.scss";
import CRMTabs from "../../components/TabsComponent/CRMTabs";
import ContractTemplates from "../../components/ContractTemplates/ContractTemplates";
import { setPageTitle } from "../../redux/actions/globalActions";
import ConfigsView from "../../components/ConfigsView/ConfigsView";
import { fetchConfigs } from "../../redux/actions/settingsActions";
import JsoneEditorContainer from "../JsonEditorPage/JsoneEditorContainer";
import AddingEntities from "../../components/AddingEntities/AddingEntities";
import { getEditableEntities, removeEntity, updEntity } from "../../redux/actions/entitiesTypesActions";


const SettingsPage = ({
  setTitle,
  title,
  entitiesTypes,
  fetchConf,
  fetchEditableEntities,
  editableEntities,
  configs,
  groups,
  ...props
}) => {
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const [data, setData] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);

  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  useEffect(() => {
    fetchEditableEntities();
  }, [fetchEditableEntities]);

  useEffect(() => {
    if (title) setTitle(title);
  }, [title, setTitle]);

  useEffect(() => {
    if (title) fetchConf(title);
  }, [title, fetchConf]);

  useEffect(() => {
    console.log('Configs data:', configs); // Логирование данных конфигураций
  }, [configs]);

 



  useEffect(() => {
    if (!editableEntities) return;

    const concatenatedEntities = [].concat(...Object.values(editableEntities));
    setData(concatenatedEntities);

    const allTypes = concatenatedEntities.map((entity) => entity.type);
    const unique = [...new Set(allTypes)];
    setUniqueTypes(unique);
  }, [editableEntities]);

  if (appGlobalState.loading || !configs) {
    return (
      <Backdrop open sx={backdropStyles}>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <CRMTabs>
      <ContractTemplates value="1" label="Шаблоны" {...props} key="1" />
      <ConfigsView
        value="2"
        label="Конфигурации"
        {...props}
        key="2"
        configs={configs}
        entities={entitiesTypes}
       
      />
      <JsoneEditorContainer
        value="3"
        label="JSON Редактор"
        {...props}
        entities={entitiesTypes}
        key="3"
      />
      {uniqueTypes && (
        <AddingEntities
          value="4"
          label="Добавление сущностей"
          data={data}
          uniqueTypes={uniqueTypes}
          updEntity={updEntity}
          removeEntity={removeEntity}
          {...props}
          key="4"
        />
      )}
    </CRMTabs>
  );
};

const mapStateToProps = (state) => ({
  globalState: state.globalState,
  configs: state.settings.payload,
  entitiesTypes: state.entitiesTypes,
  editableEntities: state.entitiesTypes?.editableEntities,
  permissions: {
    add: false,
    modify: true,
    remove: true,
  },
});

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setPageTitle(title)),
  fetchConf: (data) => dispatch(fetchConfigs(data)),
  fetchEditableEntities: () => dispatch(getEditableEntities()),
  updEntity: (data) => dispatch(updEntity(data)),
  removeEntity: (id) => dispatch(removeEntity(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);