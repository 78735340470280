/* eslint-disable no-nested-ternary */
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Tab, 
  Tabs,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { he } from "date-fns/locale";
import {
  MaskedInput,
  ReusableTextField,
} from "../ReusableInputs/ReusableInputs";
import CommentBody from "./CommentBody";
import { addNewPost, updateContact } from "../../redux/actions/contactsActions";
import CreateContractWrapper from "../CreatingContractView/CreateContractWrapper";
import hintCities from "./helpersForModals/hintCitiesForAutocomlete";
import CreateLeadFromContact from "./CreateLeadFromContact";
import SimpleYesNo from "../ReusableSimpleYesNo/ReusableSimpleYesNo";
import { SimpleLoader } from "../Loaders/SimpleLoader";
// import CreateLeadFromContact from "./CreateLeadFromContact";

const ContactDialog = ({
  open,
  onClose,
  contactFromContactDialog,
  contactHistory,
}) => {
  const initialState = {
    name: "",
    phone: "",
    nickname_inst: "",
    nickname_tg: "",
    nickname_fb: "",
    email: "",
    kid_name: "",
    year_of_birth: "",
    city: "",
    location: "",
    passport_contact: "",
    registration_contact: "",
    // utm_source: "",
    // comment: "",
  };

  const [inputs, setInputs] = React.useState(initialState);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const contactsBase = useSelector(({ contacts }) => contacts.payload);
  const [LeadModalOpen, setLeadModalOpen] = React.useState(false);
  const [expandColumn, setExpand] = React.useState(false);
  const [postValue, setPostValue] = React.useState("");
  const [openCreateContract, setOpenCreateContract] = React.useState(false);

  // we lift the state up to the parent component.
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentCommentId, setCurrentCommentId] = React.useState(0);
  const currentCommentIdRef = React.useRef(null);
    const [nickname_inst, setNickname_inst] = React.useState("");
    const [nickname_tg, setNickname_tg] = React.useState("");
    const [nickname_fb, setNickname_fb] = React.useState("");
    const [activeTab, setActiveTab] = React.useState(0); 
    const [error, setError] = React.useState({
      nickname_tg: "",
      nickname_inst: "",
      nickname_fb: "",
    });

  const handleEditClick = (commentId) => {
    if (currentCommentIdRef.current !== commentId) {
      setCurrentCommentId(commentId);
      setIsEditMode(true);
      currentCommentIdRef.current = commentId;
    }
  };
  const handleSaveClick = () => {
    setIsEditMode(false);
    setCurrentCommentId(0);
    currentCommentIdRef.current = null;
  };
  // we lift the state up to the parent component. //end

  const calculateAge = (yearOfBirth) => {
    const currentYear = new Date().getFullYear();
    const birth = new Date(yearOfBirth).getFullYear();
    return currentYear - birth;
  };

  const isFormValid = () => {
    const isNicknameValid = (nickname) => 
      nickname && nickname.trim().length >= 3 && !/\s/.test(nickname);
  
    return (
      !error?.nickname_tg &&
      !error?.nickname_inst &&
      !error?.nickname_fb &&
      isNicknameValid(inputs.nickname_tg) &&
      isNicknameValid(inputs.nickname_inst) &&
      isNicknameValid(inputs.nickname_fb)
    );
  };
  
  

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
  
    // Валидация для nickname_tg, nickname_inst, nickname_fb
    if (name === "nickname_tg" || name === "nickname_inst" || name === "nickname_fb") {
      if (value.trim().length < 3 || /\s/.test(value)) {
        setError((prevErrors) => ({
          ...prevErrors,
          [name]: "Никнейм должен содержать не менее 3 символов и не содержать пробелов",
        }));
      } else {
        setError((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Сбрасываем ошибку, если валидация пройдена
        }));
      }
    }
  
    // Обновляем состояние
    setInputs((prevInputsState) => ({
      ...prevInputsState,
      [name]: value,
    }));
  };

  const handleLeadOpen = () => {
    setLeadModalOpen(true);
  };
  const handleLeadClose = () => {
    setLeadModalOpen(false);
  };
  const handleLeadCreate = () => {
    handleLeadOpen();
  };

  const handleContactUpdate = () => {
    setLoading(true);

    // Убедимся, что все три поля присутствуют
    const updatedInputs = {
      ...inputs,
      nickname_tg: inputs.nickname_tg || "", // Если поле пустое, отправляем пустую строку
      nickname_inst: inputs.nickname_inst || "",
      nickname_fb: inputs.nickname_fb || "",
    };

    dispatch(updateContact(updatedInputs)).then(() => setLoading(false));
  };

  const handleCreateContractOpen = () => {
    setOpenCreateContract(true);
  };

  const handlePostChange = (event) => {
    setPostValue(event.target.value);
  };
  const closeAccordion = () => {
    setExpand(false);
  };
  const cancelPost = () => {
    setPostValue("");
    closeAccordion();
  };
  const sendComment = () => {
    if (postValue === "") {
      return;
    }
    dispatch(
      addNewPost({
        id_contact: contactFromContactDialog.id_contact,
        comment: postValue,
      })
    );
    setPostValue("");
  };

  // confirm modal used when the user entered a comment and did not submit it
  const [isStatusCheckModalOpen, setStatusCheckModalOpen] =
    React.useState(false);

  const openCheckModal = () => {
    setStatusCheckModalOpen(true);
  };
  const closeCheckModal = () => {
    setStatusCheckModalOpen(false);
  };

  const handleCheckModalConfirm = () => {
    dispatch(
      addNewPost({
        id_contact: contactFromContactDialog.id_contact,
        comment: postValue,
      })
    );
    setPostValue("");
    closeCheckModal();
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    if (postValue !== "") {
      openCheckModal();
    } else {
      setInputs(initialState);
      onClose();
    }
  };

  const toggleAccordion = () => {
    setExpand(!expandColumn);
  };

  React.useEffect(() => {
    const updClient = contactsBase.find(
      (item) =>
        item?.id_contact === contactFromContactDialog?.id_contact?.toString()
    );
    setInputs({
      ...updClient,
      location: "",
    });
  }, [contactsBase, open]);

  React.useEffect(() => {
    setInputs({
      ...contactFromContactDialog,
      location: "",
    });
  }, [contactFromContactDialog, open]);

  // sorting comments by date
  const sortedObjects = [];
  const unsortedObjects = [];

  contactHistory.forEach((obj) => {
    if (obj.comment && obj.id_comment) {
      sortedObjects.push(obj);
    } else {
      unsortedObjects.push(obj);
    }
  });

  sortedObjects.sort(
    (a, b) => new Date(b.date_created) - new Date(a.date_created)
  );
  const sortedData = unsortedObjects.concat(sortedObjects);

  const historyMessages = sortedData?.map((item) => (
    <CommentBody
      key={item.id_comment}
      contactHistoryForRender={item}
      isEditMode={isEditMode}
      currentCommentId={currentCommentId}
      handleEditClick={handleEditClick}
      handleSaveClick={handleSaveClick}
      id_contact={contactFromContactDialog.id_contact}
    />
  ));

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        classes={{ paper: { minHeight: "100vh" } }}
        onClose={handleClose}
      >
        {loading && <SimpleLoader />}
        <DialogTitle style={{ background: "#e5f4ff" }}>
          Контакт пользователя
        </DialogTitle>

        <DialogContent style={{ background: "#e5f4ff" }}>
          <DialogContentText component="span">
            <span
              className="client__information"
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "20px",
              }}
            >
              <div
                className="client_contact"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  background: "rgba(82,92,105,.15)",
                  padding: "30px",
                  borderRadius: "10px",
                  width: "40%",
                }}
              >
                <div style={{ textAlign: "end" }}>
                  {inputs?.year_of_birth?.length < 4 ||
                  inputs?.year_of_birth?.length > 10 ? (
                    <span style={{ opacity: 0 }}>age</span>
                  ) : (
                    `Возраст ребенка: ${calculateAge(inputs?.year_of_birth)} ${
                      calculateAge(inputs?.year_of_birth) % 10 === 1
                        ? "год"
                        : calculateAge(inputs.year_of_birth) % 10 === 2 ||
                          calculateAge(inputs.year_of_birth) % 10 === 3 ||
                          calculateAge(inputs.year_of_birth) % 10 === 4
                        ? "года"
                        : "лет"
                    }`
                  )}
                </div>
                <ReusableTextField
                  type="text"
                  label="Имя"
                  value={inputs.name}
                  name="name"
                  onChange={handleInputsChange}
                />
                <ReusableTextField
                  type="number"
                  label="телефон"
                  value={inputs.phone}
                  name="phone"
                  onChange={handleInputsChange}
                />
                <Tabs
  value={activeTab}
  onChange={(event, newValue) => setActiveTab(newValue)}
  aria-label="Никнейм"
>
  <Tab label="Telegram" />
  <Tab label="Instagram" />
  <Tab label="Facebook" />
</Tabs>

{activeTab === 0 && (
  <div>
    <ReusableTextField
      type="text"
      label="Никнейм Telegram"
      value={inputs.nickname_tg}
      name="nickname_tg"
      onChange={handleInputsChange}
      error={!!error?.nickname_tg}
      helperText={error?.nickname_tg}
    />
  </div>
)}
{activeTab === 1 && (
  <div>
    <ReusableTextField
      type="text"
      label="Никнейм Instagram"
      value={inputs.nickname_inst}
      name="nickname_inst"
      onChange={handleInputsChange}
      error={!!error?.nickname_inst}
      helperText={error?.nickname_inst}
    />
  </div>
)}
{activeTab === 2 && (
  <div>
    <ReusableTextField
      type="text"
      label="Никнейм Facebook"
      value={inputs.nickname_fb}
      name="nickname_fb"
      onChange={handleInputsChange}
      error={!!error?.nickname_fb}
      helperText={error?.nickname_fb}
    />
  </div>
)}

                <ReusableTextField
                  type="email"
                  label="email"
                  value={inputs.email}
                  name="email"
                  onChange={handleInputsChange}
                />
                <ReusableTextField
                  type="text"
                  label="ФИО ребенка"
                  value={inputs.kid_name}
                  name="kid_name"
                  onChange={handleInputsChange}
                />

                <MaskedInput
                  value={inputs.year_of_birth}
                  label="Год рождения ребенка(ГГГГ- или ГГГГ-ММ-ДД)"
                  onChange={handleInputsChange}
                  formatChars={{
                    y: "[0-2]",
                    9: "[0-9]",
                    n: "[0-1]",
                    m: "[0-9]",
                    z: "[0-3]",
                  }}
                  type="tel"
                  name="year_of_birth"
                  placeholder="ГГГГ- или ГГГГ-ММ-ДД"
                />
                {/* -----not reusable autocomlete with default cities + what user write ------ */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "5px",
                      minWidth: "120px",
                      fontSize: "14px",
                    }}
                  >
                    Город
                  </div>
                  <Autocomplete
                    freeSolo
                    autoSelect
                    size="small"
                    fullWidth
                    value={inputs.city}
                    options={hintCities}
                    onChange={(event, newValue) => {
                      setInputs((prevInputsState) => ({
                        ...prevInputsState,
                        city: newValue || "",
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
                <ReusableTextField
                  type="text"
                  label="Паспортные данные"
                  value={inputs.passport_contact}
                  name="passport_contact"
                  onChange={handleInputsChange}
                />
                <ReusableTextField
                  type="text"
                  label="Регистрация"
                  value={inputs.registration_contact}
                  name="registration_contact"
                  onChange={handleInputsChange}
                />
                {/* <ReusableTextField
                  type="text"
                  label="utm source"
                  value={inputs.utm_source}
                  name="utm_source"
                  onChange={handleInputsChange}
                /> */}
                <div className="contact__controls">
                <Button onClick={handleContactUpdate} >
                  Обновить контакт
                </Button>
                </div>
              </div>

              <div
                className="client_comments"
                style={{
                  background: "rgba(82,92,105,.15)",
                  width: "55%",
                  borderRadius: "10px",
                  // height: "80vh",
                  // overflowY: "scroll",
                }}
              >
                <Button onClick={toggleAccordion}>Комментарий</Button>
                <Button onClick={handleLeadCreate}>Создать лид</Button>
                <Button>создать задачу</Button>
                <Button onClick={handleCreateContractOpen}>
                  {" "}
                  Создать договор{" "}
                </Button>
                <Divider />
                {expandColumn && (
                  <Box
                    className="comment__add_area"
                    style={{
                      borderRadius: "10px",
                      background: "#fff",
                      padding: "15px",
                      margin: "10px",
                    }}
                  >
                    <ReusableTextField
                      type="text"
                      multiline
                      label="Оставить комментарий"
                      value={postValue}
                      name="comments"
                      onChange={handlePostChange}
                    />
                    <Button
                      variant="contained"
                      size="small"
                      onClick={sendComment}
                    >
                      {" "}
                      Отправить
                    </Button>
                    <Button size="small" onClick={cancelPost}>
                      Отмена
                    </Button>
                  </Box>
                )}
                {historyMessages}
              </div>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      <CreateLeadFromContact
        open={LeadModalOpen}
        onClose={handleLeadClose}
        contact={contactFromContactDialog}
      />
      <CreateContractWrapper
        open={openCreateContract}
        contactFromContactDialog={contactFromContactDialog}
        setOpen={setOpenCreateContract}
      />
      <SimpleYesNo
        isOpen={isStatusCheckModalOpen}
        onClose={closeCheckModal}
        onConfirm={handleCheckModalConfirm}
        content="Вы не сохранили комментарий. Сохранить?"
      />
    </div>
  );
};

export default React.memo(ContactDialog);
